import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { User } from 'src/app/commons/models/user.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {

  user: User;
  constructor(private store$: Store<AppState>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data && this.data.user) {
      this.user = new User(this.data.user);
    }
  }

  close() {
    this.store$.dispatch(UserActions.closePaymentInfoDialog());
  }
}








