import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ClientFilters } from 'src/app/commons/models/client.model';


@Component({
  selector: 'client-filters',
  templateUrl: './client-filters.component.html',
  styleUrls: ['./client-filters.component.scss']
})
export class ClientFiltersComponent {

  @Output()
  onFilter: EventEmitter<ClientFilters> = new EventEmitter();

  private _defaultFilters: ClientFilters;
  filtersForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      ids: [],
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getClientFilters());
  }

  private _getClientFilters(): ClientFilters {
    let filters: ClientFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.ids = values.ids;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      ids: [],
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): ClientFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: ClientFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          ids: this.defaultFilters.ids,
        })
      }
    }
  }
}