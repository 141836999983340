import { createAction, props } from '@ngrx/store';

import { UserDTO } from '../../commons/models/user.model';

export const login = createAction('[Auth] Login', props<{ username: string, password: string }>());
export const loginCompleted = createAction('[Auth] Login Completed', props<{ token: string, currentUser: UserDTO }>());
export const loginFailed = createAction('[Auth] Login Failed', props<{ error: any }>());

export const logout = createAction('[Auth] Logout', props<{ showConfirm: boolean }>());
export const logoutCompleted = createAction('[Auth] Logout Completed', props<{ redirectUrl?: string }>());
export const logoutCancelled = createAction('[Auth] Logout Cancelled');

export const loadCurrentUser = createAction('[Auth] Load current user', props<{ goToHome: boolean }>());
export const loadCurrentUserCompleted = createAction('[Auth] Load current user completed', props<{ currentUser: UserDTO, goToHome?: boolean }>());
export const loadCurrentUserFailed = createAction('[Auth] Load current user failed', props<{ error: any }>());

export const directLogin = createAction('[Auth] Direct login', props<{ token: string; }>());
