import { Pipe, PipeTransform } from '@angular/core';
import { MessageStatus } from 'src/app/commons/models/message.model';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {
  transform(value: MessageStatus): unknown {
    switch (value) {
      case "PENDING":
        return "ATTESA";
      case "COMPLETED":
        return "COMPLETATO";
      case "FAILED":
        return "FALLITO";
      case "CANCELLED":
        return "ANNULLATO";
      case "RESCHEDULED":
        return "RIPROGRAMMATO";
      case "BLACKLISTED":
        return "BLACKLIST";
      default:
        return "";
    }
  }

}
