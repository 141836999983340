import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent {
  @Output()
  onSendMessages: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onCancelMessages: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onRescheduleMessages: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  sendMessages() {
    this.onSendMessages.emit();
  }

  cancelMessages() {
    this.onCancelMessages.emit();
  }

  rescheduleMessages() {
    this.onRescheduleMessages.emit();
  }
}
