import { Action, createReducer, on } from '@ngrx/store';
import { TemplateDTO, TemplateFilters } from 'src/app/commons/models/template.model';

import * as SequenceActions from '../actions/sequence.actions';
import * as TemplateActions from '../actions/template.actions';

export interface TemplateState {
  list: TemplateDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: TemplateFilters,
  dialogId: string,
  template: TemplateDTO,
  sequenceId: number,
};

const initialState: TemplateState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  template: null,
  sequenceId: null,
};

const templateReducer = createReducer(
  initialState,
  on(SequenceActions.loadSequence, (state, { id }) => {
    return { ...state, sequenceId: id };
  }),
  on(SequenceActions.setSequenceId, (state, { id }) => {
    return { ...state, sequenceId: id };
  }),
  on(TemplateActions.loadTemplatesCompleted, (state, { templates, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, list: templates, currentPage, total, perPage, order, direction, filters };
  }),
  on(TemplateActions.saveTemplateCompleted, (state, { template }) => {
    return { ...state, template };
  }),
  on(TemplateActions.templateDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(TemplateActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
);

export function reducer(state: TemplateState | undefined, action: Action) {
  return templateReducer(state, action);
}
