<header mat-dialog-title class="u-flex-full">
  <span>
    <span>Cambia password</span><span *ngIf="user"> per {{user.name}}</span>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="passwordForm" novalidate>
    <div class="u-full-width">
      <span class="u-flex-full">
        <mat-form-field>
          <input type="password" placeholder="Nuova password" matInput formControlName="newPass" required>
          <mat-error>Campo obbligatorio</mat-error>
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <input type="password" placeholder="Conferma password" matInput formControlName="confirmPass">
          <mat-error *ngIf="passwordForm.controls['confirmPass'].errors?.notEquivalent">Le
            password non coincidono</mat-error>
        </mat-form-field>
      </span>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="passwordForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="updatePassword()"
      [disabled]="passwordForm.pristine || !passwordForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
