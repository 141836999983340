import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { map, Observable } from 'rxjs';
import { Client } from 'src/app/commons/models/client.model';
import { Message, MessageFilters, MessageStatus } from 'src/app/commons/models/message.model';

import { BIG_PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type MessageColumn =
  | "selection"
  | "external_id"
  | "created_at"
  | "process_date"
  | "telefono"
  | "stato"
  | "nomeCliente"
  | "invioMassivo"
  | "sourceType"
  | "provider"
  | "actions";

@Component({
  selector: "message-list",
  templateUrl: "./message-list.component.html",
  styleUrls: ["./message-list.component.scss"],
})
export class MessageListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  messages: Observable<Message[]>;

  @Input()
  clients: Observable<Client[]>;

  @Input()
  defaultFilters: MessageFilters;

  @Input()
  pageSize: number;

  @Input()
  loaded: boolean;

  pageSizeOptions = BIG_PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: MessageColumn[] = [
    "selection",
    "external_id",
    "created_at",
    "process_date",
    "telefono",
    "stato",
    "sourceType",
    "nomeCliente",
    "actions",
  ];

  @Output()
  onLoadMessages: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<MessageFilters> =
    new EventEmitter<MessageFilters>();
  @Output()
  onSelectMessage: EventEmitter<Message> = new EventEmitter<Message>();
  @Output()
  onCancelMessage: EventEmitter<Message> = new EventEmitter<Message>();
  @Output()
  onCancelSequence: EventEmitter<Message> = new EventEmitter<Message>();
  @Output()
  onRescheduleMessage: EventEmitter<Message> = new EventEmitter<Message>();
  @Output()
  onCancelMessages: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output()
  onRescheduleMessages: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output()
  onSendMessages: EventEmitter<number[]> = new EventEmitter<number[]>();

  selectedMessages: number[] = [];
  selectedStatus: MessageStatus;
  pending: MessageStatus = "PENDING";
  cancelled_status: MessageStatus = "CANCELLED";
  failed: MessageStatus = "FAILED";

  constructor() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  onFilter(filters: MessageFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  select(message: Message) {
    this.onSelectMessage.emit(message);
  }

  cancelMessage(message: Message) {
    this.onCancelMessage.emit(message);
  }

  cancelSequence(message: Message) {
    this.onCancelSequence.emit(message);
  }

  rescheduleMessage(message: Message) {
    this.onRescheduleMessage.emit(message);
  }

  selectMessage(message: Message, add: boolean) {
    if (this.selectedMessages.length == 0) {
      this.selectedStatus = message.messageStatus;
    }

    if (add) {
      this.selectedMessages.push(message.id);
    } else {
      this.selectedMessages.splice(
        this.selectedMessages.indexOf(message.id),
        1
      );
    }

    if (this.selectedMessages.length == 0) {
      this.selectedStatus = undefined;
    }
  }

  selectAllMessages(add: boolean) {
    if (add) {
      this.messages
        .pipe(
          map((messages) =>
            messages
              .filter(
                (message) =>
                  message.messageStatus == this.pending ||
                  message.messageStatus == this.failed
              )
              .map((message) => message.id)
          )
        )
        .subscribe((messages) => (this.selectedMessages = messages));
    } else {
      this.selectedMessages = [];
    }
  }

  cancelMessages() {
    this.onCancelMessages.emit(this.selectedMessages);
    this.selectedMessages = [];
    this.selectedStatus = undefined;
  }

  rescheduleMessages() {
    this.onRescheduleMessages.emit(this.selectedMessages);
    this.selectedMessages = [];
    this.selectedStatus = undefined;
  }

  sendMessages() {
    this.onSendMessages.emit(this.selectedMessages);
    this.selectedMessages = [];
    this.selectedStatus = undefined;
  }
}
