<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="user">Modifica utente: {{ user.name }}</span>
    <span *ngIf="!user">Nuovo utente</span>
  </span>
  <span *ngIf="user">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="changePassword()">
        <mat-icon>vpn_key</mat-icon>
        Cambia Password
      </button>
      <button mat-menu-item (click)="deleteUser()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="userForm" novalidate>
    <mat-checkbox formControlName="active">
      <mat-label class="formGroup__label"> Attivo </mat-label>
    </mat-checkbox>

    <mat-form-field class="u-full-width">
      <input matInput placeholder="Nome" formControlName="name" type="text" required />
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="E-mail" formControlName="email" type="email" required />
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Ruolo</mat-label>
        <mat-select formControlName="role" required>
          <mat-option value="admin">{{ "admin" | userRole }}</mat-option>
          <mat-option value="partner">{{ "partner" | userRole }}</mat-option>
          <mat-option value="client">{{ "client" | userRole }}</mat-option>
        </mat-select>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="!user" class="u-full-width">
      <span class="u-flex-full">
        <mat-form-field>
          <input type="password" placeholder="Password" autocomplete="new-password" matInput formControlName="password"
            required />
          <mat-error>Campo obbligatorio</mat-error>
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <input type="password" placeholder="Conferma password" matInput formControlName="confirmPass" />
          <mat-error *ngIf="userForm.controls['confirmPass'].errors?.notEquivalent">Le password non
            coincidono</mat-error>
        </mat-form-field>
      </span>
    </div>

    <ng-container *ngIf="isPartner">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Url di logout" formControlName="logoutRedirectUrl" type="text" />
        <mat-hint>URL da usare per il logout</mat-hint>
      </mat-form-field>
      <div class="formGroup__wrapper">
        <mat-label class="formGroup__label"> Webhook Partner per avviso disconnessione </mat-label>
        <mat-form-field class="u-full-width">
          <input matInput placeholder="Hook" formControlName="statusHook" type="url" />
          <mat-hint align="end" matTooltip="URL che verr&agrave; chiamato alla disconnesione di un cliente">
            <mat-icon>help</mat-icon>
          </mat-hint>
        </mat-form-field>
        <button mat-raised-button [disabled]="!userForm.get('statusHook')?.value" color="primary" type="button"
          (click)="testWebhook()">Test Webhook</button>
      </div>
    </ng-container>

    <div *ngIf="!user">
      <div class="formGroup__wrapper" [hidden]="!isClient">
        <mat-label class="formGroup__label"> Cliente </mat-label>
        <mat-form-field class="formGroup">
          <input matInput placeholder="Nome" formControlName="clientName" type="text" />
          <mat-error>Campo obbligatorio</mat-error>
        </mat-form-field>
        <mat-form-field class="formGroup">
          <input matInput placeholder="Telefono" formControlName="clientPhone" type="tel" />
          <mat-error>Campo obbligatorio</mat-error>
        </mat-form-field>
      </div>

      <div class="formGroup__wrapper" [hidden]="!isClient">
        <mat-label class="formGroup__label"> Sessione </mat-label>
        <mat-form-field class="formGroup">
          <mat-select formControlName="clientSessionType" placeholder="Tipologia">
            <mat-option *ngFor="let type of types" [value]="type">
              {{ type | sessionType }}
            </mat-option>
          </mat-select>
          <mat-error>Campo obbligatorio</mat-error>
        </mat-form-field>
        <div [hidden]="!isSendApp">
          <mat-form-field class="formGroup u-group-full-width">
            <input matInput placeholder="Access Token" formControlName="clientSessionToken" type="text" />
          </mat-form-field>

          <mat-form-field class="formGroup u-group-full-width">
            <input matInput placeholder="Instance Id" formControlName="clientSessionId" type="text" />
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- client only -->
    <ng-container *ngIf="isClient">
      <div class="formGroup__wrapper">
        <mat-label class="formGroup__label"> Controllo Stato </mat-label>
        <mat-form-field class="formGroup">
          <input matInput placeholder="Telefono" formControlName="statusPhone" type="text" required />
          <mat-hint align="end"
            matTooltip="Numero di telefono a cui verranno inviati i messaggi per il controllo dello stato">
            <mat-icon>help</mat-icon>
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="formGroup">
          <input matInput placeholder="Email" formControlName="statusEmails" type="text" />
          <mat-hint align="end"
            matTooltip="Lista di email seperate da , (virgola) che verrano contattate nel caso in cui la sessione cambi stato">
            <mat-icon>help</mat-icon>
          </mat-hint>
        </mat-form-field>

        <mat-checkbox formControlName="watiHook" labelPosition="before">
          <mat-label class="formGroup__label"> Messaggio WATI </mat-label>
        </mat-checkbox>
      </div>

      <div class="formGroup__wrapper" *showFor="['admin']">
        <mat-label class="formGroup__label"> Assegna Partner </mat-label>

        <mat-form-field class="u-full-width">
          <mat-label>Partner</mat-label>
          <app-user-field formControlName="partner" [userFilters]="{ roles: ['partner'] }"></app-user-field>
        </mat-form-field>
      </div>


      <div class="formGroup__wrapper">
        <mat-label class="formGroup__label"> Fatturazione </mat-label>
        <div class="u-flex-full">
          <mat-radio-group formControlName="profile" aria-label="Profilo" class="group-radio">
            <mat-label class="formGroup__label"> Profilo: </mat-label>

            <div class="radio-button-container">
              <mat-radio-button value="Account gratuito">Account gratuito</mat-radio-button>
              <mat-radio-button value="Fase di Test">Fase di Test</mat-radio-button>
              <mat-radio-button value="Subscription Mensile">Subscription Mensile</mat-radio-button>
              <mat-radio-button value="Subscription Annuale">Subscription Annuale</mat-radio-button>
            </div>

            <mat-error *ngIf="userForm.get('profile')?.errors">Campo obbligatorio</mat-error>
          </mat-radio-group>
        </div>

        <mat-form-field class="formGroup">
          <input matInput placeholder="Scadenza" formControlName="expiration" type="text" />
        </mat-form-field>

        <mat-form-field class="formGroup">
          <input matInput placeholder="Quota (Iva compresa se dovuta)" formControlName="amount" type="text" />
        </mat-form-field>

        <mat-form-field class="formGroup u-flex-full">
          <input matInput placeholder="Metodo di pagamento" formControlName="paymentMethod" type="text" />
        </mat-form-field>
      </div>
    </ng-container>

    <div>
      <div class="formGroup__wrapper">
        <mat-label class="formGroup__label"> Funzionalità attive</mat-label>


        <mat-checkbox formControlName="hasSingleMessage">
          <mat-label class="formGroup__label"> Singolo messaggio </mat-label>
        </mat-checkbox>

        <mat-checkbox formControlName="hasSequence">
          <mat-label class="formGroup__label"> Gestione Sequenze </mat-label>
        </mat-checkbox>

        <mat-checkbox formControlName="hasMassMessage">
          <mat-label class="formGroup__label"> Invio Massivo </mat-label>
        </mat-checkbox>

        <mat-checkbox formControlName="hasAutoResponder">
          <mat-label class="formGroup__label"> Autorisponditore </mat-label>
        </mat-checkbox>
      </div>
    </div>

    <div>
      <div class="formGroup__wrapper">
        <mat-label class="formGroup__label">
          Funzionalità visibili all'utente</mat-label>

        <mat-checkbox formControlName="showSingleMessage">
          <mat-label class="formGroup__label"> Singolo messaggio </mat-label>
        </mat-checkbox>

        <mat-checkbox formControlName="showSequence">
          <mat-label class="formGroup__label"> Gestione Sequenze </mat-label>
        </mat-checkbox>

        <mat-checkbox formControlName="showMassMessage">
          <mat-label class="formGroup__label"> Invio Massivo </mat-label>
        </mat-checkbox>

        <mat-checkbox formControlName="showAutoResponder">
          <mat-label class="formGroup__label"> Autorisponditore </mat-label>
        </mat-checkbox>
      </div>
    </div>

    <div class="formGroup__wrapper">
      <mat-label class="formGroup__label"> Altro </mat-label>

      <div class="u-flex-full justify-none">
        <mat-form-field class="w-full">
          <ngx-mat-file-input formControlName="logo" [placeholder]="logoPlaceholder">
          </ngx-mat-file-input>

          <mat-error>File non valido</mat-error>
          <mat-hint align="end" matTooltip="Se non è selezionato nessun file verrà scelto il logo di
            Trilly">
            <mat-icon>help</mat-icon>
          </mat-hint>
        </mat-form-field>

        <mat-icon (click)="clearLogo()" matSuffix color="primary">backspace</mat-icon>
      </div>

      <div class="u-flex-full justify-none">
        <mat-form-field class="w-full">
          <input placeholder="Colore" matInput [ngxMatColorPicker]="colorPicker" formControlName="color" />
          <ngx-mat-color-toggle matSuffix [for]="colorPicker"></ngx-mat-color-toggle>
          <ngx-mat-color-picker #colorPicker name="color"></ngx-mat-color-picker>
        </mat-form-field>
      </div>
    </div>

    <div [hidden]="!isClient" style="margin: 8px 0">
      <button *ngIf="user" mat-stroked-button color="primary" type="reset" (click)="goToClient()">
        Cliente
      </button>
    </div>
  </form>
  <!-- {{userForm.value | json}} -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()" [disabled]="userForm.pristine">
      Reset
    </button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="userForm.pristine || !userForm.valid">
      Salva
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Chiudi
  </button>
</mat-dialog-actions>