import { AbstractControl } from '@angular/forms';
import { Template, TemplateDTO } from 'src/app/commons/models/template.model';

import { Base, BaseDTO } from './base.model';
import { Session, SessionDTO } from './session.model';

export interface SequenceFilters {
  search?: string;
  showInactive?: boolean;
  ids?: number[];
}

export const defaultFilters: SequenceFilters = {
  search: "",
  showInactive: false,
  ids: []
}

export interface SequenceDTO extends BaseDTO {
  name: string;
  session_id: number;
  active: boolean;
  phone_field: string;
  templates: TemplateDTO[];
  session: SessionDTO
}

export class Sequence extends Base {
  name: string;
  sessionId: number;
  phoneField: string;
  templates: Template[];
  active: boolean
  session: Session;

  constructor(source: SequenceDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.sessionId = source.session_id;
      this.active = source.active;
      this.phoneField = source.phone_field;
      if (source.templates) {
        this.templates = source.templates.map(t => new Template(t));
        this.addLoadedRelation('templates');
      }
      if (source.session) {
        this.session = new Session(source.session);
        this.addLoadedRelation('session');
      }
    }
  }

  toDTO(): SequenceDTO {
    let result: SequenceDTO = <SequenceDTO>super.toDTO();
    result.name = this.name;
    result.session_id = this.sessionId;
    result.active = this.active;
    result.phone_field = this.phoneField;
    if (this.templates) {
      result.templates = this.templates.map(t => t.toDTO());
    }
    if (this.session) {
      result.session = this.session.toDTO();
    }

    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Sequence) {
    const formModel = formGroup.value;
    let sequence: Sequence = new Sequence(null);

    if (original) {
      sequence.id = original.id;
      sequence.sessionId = original.sessionId;
      sequence.templates = original.templates;
    }
    sequence.name = formModel.name;
    sequence.active = formModel.active;
    sequence.phoneField = formModel.phoneField;

    return sequence;
  }
}

export interface Hooks {
  start: string;
  stop: string;
}