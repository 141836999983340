import { createSelector } from '@ngrx/store';

import { selectLibraryState } from '../reducers';
import { LibraryState } from '../reducers/library.reducer';
import * as ClientSelector from '../selectors/client.selectors';
import * as SequenceSelectors from '../selectors/sequence.selectors';


export const getFiles = createSelector(
  selectLibraryState,
  (state: LibraryState) => state.files ? Array.from(state.files) : null
);

export const getLibraryDialogId = createSelector(
  selectLibraryState,
  (state: LibraryState) => state.libraryDialogId
);

export const getSessionId = createSelector(
  ClientSelector.getSession,
  SequenceSelectors.getSessionId,
  (sessionFromClient, sessionId) => sessionFromClient?.id || sessionId
)
