import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { BlacklistFilters } from 'src/app/commons/models/blacklist.model';

@Component({
  selector: 'app-blacklist-filters',
  templateUrl: './blacklist-filters.component.html',
  styleUrls: ['./blacklist-filters.component.scss']
})
export class BlacklistFiltersComponent {
  @Output()
  onFilter: EventEmitter<BlacklistFilters> = new EventEmitter();

  private _defaultFilters: BlacklistFilters;
  filtersForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      phone: "",
      user: null
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getBlacklistFilters());
  }

  private _getBlacklistFilters(): BlacklistFilters {
    let filters: BlacklistFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.phone = values.phone;
      filters.user = values.user;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      phone: "",
      user: null
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): BlacklistFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: BlacklistFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          phone: this.defaultFilters.phone,
          user: this.defaultFilters.user
        })
      }
    }
  }
}
