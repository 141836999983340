import { UntypedFormGroup } from '@angular/forms';
import { formatDateForBackend } from 'src/app/helpers/time.utils';

import { dateFromDTO } from '../../helpers/time.utils';
import { Base, BaseDTO } from './base.model';

export type MessageFilters = Partial<{
  search: string;
  createdAtFrom: Date;
  createdAtTo: Date;
  sentAtFrom: Date;
  sentAtTo: Date;
  dateTo: Date;
  ids: number[];
  phone: string;
  sequenceId: number;
  clientId: number;
  sessionId: number;
  templateId: number;
  statuses: MessageStatus[];
  types: SourceType[];
  invioMassivo?: boolean;
  failReason?: string;
  externalId?: string;
}>;

export type MessageStatus = "PENDING" | "COMPLETED" | "FAILED" | "CANCELLED" | "RESCHEDULED" | "BLACKLISTED";
export type SourceType = "SINGLE" | "AUTOMATION" | "MASSIVE" | "SEQUENCE";

export const MESSAGE_STATUSES: MessageStatus[] = ["PENDING", "COMPLETED", "FAILED", "CANCELLED", "RESCHEDULED", "BLACKLISTED"];
export const SOURCE_TYPES: SourceType[] = ["SINGLE", "SEQUENCE", "MASSIVE", "AUTOMATION"];

export const FAIL_REASONS: string[] = [
  "Numero non registrato su WhatsApp",
  "Errore nell'invio del messaggio",
  "Sessione non connessa",
  "Campo telefono non trovato",
  "Estensione file non valida",
  "Numero di telefono non valido",
  "Campo telefono non valido",
  "Schedulazione fallita"
].sort();

export interface MessageDTO extends BaseDTO {
  template_id: number;
  process_date: string;
  content: string;
  phone: string;
  request_body: string;
  message_status: MessageStatus;
  resp_message: string;
  cliente_nome?: string;
  template_nome?: string;
  template_provider?: string;
  resp_body: string;
  sequence_id?: number;
  client_id?: number;
  source_type: SourceType;
  media_nome: string;
  media_url: string;
  external_id: string;
}

export class Message extends Base {
  templateId: number;
  processDate: Date;
  content: string;
  phone: string;
  requestBody: string;
  messageStatus: MessageStatus;
  responseMessage: string;
  nomeCliente: string;
  nomeTemplate: string;
  providerTemplate: string;
  responseBody: string;
  sequenceId?: number;
  clientId?: number;
  sourceType: SourceType;
  mediaNome: string;
  mediaUrl: string;
  externalId: string;

  constructor(source: MessageDTO) {
    super(source);
    if (source) {
      this.templateId = source.template_id;
      this.processDate = dateFromDTO(source.process_date);
      this.content = source.content;
      this.phone = source.phone;
      try {
        this.requestBody = JSON.parse(source.request_body);
      } catch (e) {
        // console.log('invalid json');
        this.requestBody = source.request_body;
      }
      this.messageStatus = source.message_status;
      this.responseMessage = source.resp_message;
      try {
        this.responseBody = JSON.parse(source.resp_body);
      } catch (e) {
        console.log('invalid json');
        this.responseBody = source.resp_body;
      }
      this.nomeCliente = source?.cliente_nome;
      this.nomeTemplate = source?.template_nome;
      this.providerTemplate = source?.template_provider;
      this.sequenceId = source?.sequence_id;
      this.clientId = source?.client_id;
      this.sourceType = source.source_type;
      this.mediaNome = source.media_nome;
      this.mediaUrl = source.media_url;
      this.externalId = source.external_id;
    }
  }

  toDTO(): MessageDTO {
    let result: MessageDTO = <MessageDTO>super.toDTO();
    result.template_id = this.templateId;
    result.process_date = this.processDate ? formatDateForBackend(this.processDate, true) : null;
    result.content = this.content;
    result.phone = this.phone;
    result.request_body = this.requestBody;
    result.message_status = this.messageStatus;
    result.resp_message = this.responseMessage;
    result.resp_body = this.responseBody;
    result.cliente_nome = this.nomeCliente;
    result.template_nome = this.nomeTemplate;
    result.template_provider = this.providerTemplate;
    result.source_type = this.sourceType;
    result.media_nome = this.mediaNome;
    result.media_url = this.mediaUrl;
    result.external_id = this.externalId;

    return result;
  }
}

export interface SingleMessageDTO {
  phone: string;
  media_url?: string;
  media_name?: string;
  testo: string;
  date: string;
}

export class SingleMessage {
  phone: string;
  mediaUrl?: string;
  mediaName?: string;
  testo: string;
  date?: Date;
  constructor(source: SingleMessageDTO) {
    if (source) {
      this.phone = source.phone;
      this.mediaUrl = source.media_url;
      this.mediaName = source.media_name;
      this.testo = source.testo;
      if (source.date) {
        this.date = new Date(source.date);
      }
    }
  }

  toDTO(): SingleMessageDTO {
    return {
      phone: this.phone,
      media_url: this?.mediaUrl,
      media_name: this?.mediaName,
      testo: this.testo,
      date: this.date ? formatDateForBackend(this.date, true) : null
    }
  }

  static fromFormGroup(formGroup: UntypedFormGroup): SingleMessage {
    const formModel = formGroup.getRawValue();
    let message: SingleMessage = new SingleMessage(null);
    message.phone = formModel.phone;
    message.mediaUrl = formModel?.mediaUrl;
    message.mediaName = formModel?.mediaName;
    message.testo = formModel.testo;
    message.date = formModel.date;

    return message;
  }
}
