import { Action, createReducer, on } from '@ngrx/store';
import { Filter, SheetData, SheetDTO } from 'src/app/commons/models/sheets.model';
import * as SheetsActions from '../actions/sheets.actions';

export interface SheetsState {
  authUrl?: string,
  email?: string,
  // token?: AuthToken,
  dialogId?: string,
  testDialogId?: string

  sheets?: SheetDTO[],
  filters?: Filter[],

  sheet?: SheetDTO,
  sheetData?: string[][],
};

const initialState: SheetsState = {
  authUrl: null,
  // token: null,
  dialogId: null,
  testDialogId: null,

  sheets: [],
  filters: [],

  sheet: null,
  sheetData: null,
};

const templateReducer = createReducer(
  initialState,
  on(SheetsActions.loadCompleted, (state, { authUrl, email }) => {
    return { ...state, authUrl, email };
  }),
  // on(SheetsActions.loadTokenCompleted, (state, { token }) => {
  //   return { ...state, token };
  // }),
  on(SheetsActions.readSheet, (state, { sheet }) => {
    return { ...state, sheet };
  }),
  on(SheetsActions.readSheetCompleted, (state, { data }) => {
    return { ...state, sheetData: data };
  }),
  on(SheetsActions.disconnectCompleted, (state) => {
    return { ...state, token: null };
  }),
  on(SheetsActions.sheetEditDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(SheetsActions.sheetDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId, sheet: null, sheetData: null };
  }),
  on(SheetsActions.loadSheetsCompleted, (state, { sheets }) => {
    return { ...state, sheets };
  }),
  on(SheetsActions.loadFiltersCompleted, (state, { filters }) => {
    return { ...state, filters };
  }),
  on(SheetsActions.closeSheetDialog, (state) => {
    return { ...state, filters: [] };
  }),
  on(SheetsActions.closeSheetEditDialog, (state) => {
    return { ...state, sheet: null, sheetData: null };
  }),
  on(SheetsActions.sheetTestDialogOpened, (state, { dialogId }) => {
    return { ...state, testDialogId: dialogId };
  })
);

export function reducer(state: SheetsState | undefined, action: Action) {
  return templateReducer(state, action);
}
