import { createSelector } from '@ngrx/store';

import { selectSequenceState } from '../reducers';
import { SequenceState } from '../reducers/sequence.reducer';

export const getSessionId = createSelector(
  selectSequenceState,
  (state: SequenceState) => state.sessionId || state.sequence?.session_id,
);

export const getSequencesTableState = createSelector(
  selectSequenceState,
  (state: SequenceState) => { return { sessionId: state.sessionId, total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters } }
);

const getAllSequences = createSelector(
  selectSequenceState,
  (state: SequenceState) => state.list
);

export const getSequences = createSelector(
  getSessionId,
  getAllSequences,
  (sessionId, sequences) => {
    return sequences.filter(sequence => sequence.session_id === sessionId);
  }
)

export const getTotalSequences = createSelector(
  getSequencesTableState,
  (tableState) => tableState.total
);

export const getSequencesCurrentPage = createSelector(
  getSequencesTableState,
  (tableState) => tableState.currentPage
);

export const getSequencesPerPage = createSelector(
  getSequencesTableState,
  (tableState) => tableState.perPage
);

export const getSequencesOrder = createSelector(
  getSequencesTableState,
  (tableState) => tableState.order
);

export const getSequencesDirection = createSelector(
  getSequencesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getSequencesTableState,
  (tableState) => tableState.filters
);

export const getSequenceDialogId = createSelector(
  selectSequenceState,
  (state: SequenceState) => state.dialogId
);

export const getSequence = createSelector(
  selectSequenceState,
  (state: SequenceState) => state.sequence
);

export const getAllClients = createSelector(
  selectSequenceState,
  (state: SequenceState) => state.clients
);
