import { createAction, props } from '@ngrx/store';

import { Blacklist, BlacklistDTO, BlacklistFilters } from '../../commons/models/blacklist.model';

export const loadBlacklists = createAction('[Blacklists] Load blacklists', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: BlacklistFilters, includes?: string[] }>());
export const loadBlacklistsCompleted = createAction('[Blacklists] Load blacklists Completed', props<{ blacklists: BlacklistDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: BlacklistFilters, includes?: string[] }>());
export const loadBlacklistsFailed = createAction('[Blacklists] Load blacklists Failed', props<{ error: any }>());

export const changePage = createAction('[Blacklists] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Blacklists] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Blacklists] Change filters', props<{ filters: BlacklistFilters }>());

export const editBlacklist = createAction('[Blacklists] Edit blacklist', props<{ blacklist: Blacklist }>());
export const blacklistDialogOpened = createAction('[Blacklists] Detail dialog opened', props<{ dialogId: string }>());
export const closeBlacklistDialog = createAction('[Blacklists] Close detail dialog');

export const saveBlacklist = createAction('[Blacklists] Save blacklist', props<{ blacklist: Blacklist }>());
export const saveBlacklistCompleted = createAction('[Blacklists] Save blacklist completed', props<{ blacklist: BlacklistDTO }>());
export const saveBlacklistFailed = createAction('[Blacklists] Save blacklist failed', props<{ error: any }>());

export const deleteBlacklist = createAction('[Blacklists] Delete blacklist', props<{ blacklist: BlacklistDTO }>());
export const deleteBlacklistCompleted = createAction('[Blacklists] Delete blacklist completed', props<{ blacklist: BlacklistDTO }>());
export const deleteBlacklistCancelled = createAction('[Blacklists] Delete blacklist cancelled');
export const deleteBlacklistFailed = createAction('[Blacklists] Delete blacklist failed', props<{ error: any }>());