import { createAction, props } from '@ngrx/store';
import { ClientDTO } from 'src/app/commons/models/client.model';

import { Template } from '../../commons/models/template.model';
import { MessageDTO } from '../../commons/models/message.model';
import { Sequence, SequenceDTO, SequenceFilters } from '../../commons/models/sequence.model';
import { UserDTO } from 'src/app/commons/models/user.model';

export const setSequenceId = createAction('[Sequence] Set Sequence Id', props<{ id: number }>());

export const loadSequences = createAction('[Sequences] Load sequences', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: SequenceFilters }>());
export const loadSequencesCompleted = createAction('[Sequences] Load sequences Completed', props<{ sequences: SequenceDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: SequenceFilters }>());
export const loadSequencesFailed = createAction('[Sequences] Load sequences Failed', props<{ error: any }>());

export const loadSequence = createAction('[Sequences] Load sequence', props<{ id: number }>());
export const loadSequenceCompleted = createAction('[Sequences] Load sequence Completed', props<{ sequence: SequenceDTO }>());
export const loadSequenceFailed = createAction('[Sequences] Load sequence Failed', props<{ error: any }>());

export const changePage = createAction('[Sequences] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Sequences] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Sequences] Change filters', props<{ filters: SequenceFilters }>());

export const editSequence = createAction('[Sequences] Edit sequence', props<{ sequence: SequenceDTO }>());
export const selectSequence = createAction('[Sequences] Select sequence', props<{ sequence: SequenceDTO }>());
export const setSequence = createAction('[Sequences] Set sequence', props<{ sequence: SequenceDTO }>());

export const sequenceDialogOpened = createAction('[Sequences] Detail dialog opened', props<{ dialogId: string }>());
export const closeSequenceDialog = createAction('[Sequences] Close detail dialog');

export const testSequence = createAction('[Sequences] Test sequence', props<{ sequence: SequenceDTO, data: Object }>());
export const testSequenceCompleted = createAction('[Sequences] Test sequence completed', props<{ message: MessageDTO }>());
export const testSequenceFailed = createAction('[Sequences] Test sequence failed', props<{ error: any }>());

export const copySequence = createAction('[Sequences] Copy sequence webhook', props<{ sequence: Sequence, templates?: Template[] }>());

export const cloneSequence = createAction('[Sequences] Clone sequence', props<{ sequence: SequenceDTO }>());
export const cloneSequenceCompleted = createAction('[Sequences] Clone sequence completed', props<{ sequence: SequenceDTO }>());
export const cloneSequenceFailed = createAction('[Sequences] Clone sequence failed', props<{ error: any }>());

export const loadAllClients = createAction('[Sequences] Load all clients');
export const loadAllClientsCompleted = createAction('[Sequences] Load all clients completed', props<{ clients: ClientDTO[] }>());
export const loadAllClientsFailed = createAction('[Sequences] Load all clients failed', props<{ error: any }>());

export const selectSequenceClient = createAction('[Sequences] Select client', props<{ sequence: SequenceDTO }>());
export const moveSequence = createAction('[Sequences] Move sequence', props<{ sequence: SequenceDTO, client: ClientDTO }>());
export const moveSequenceCompleted = createAction('[Sequences] Move sequence completed', props<{ sequence: SequenceDTO }>());
export const moveSequenceFailed = createAction('[Sequences] Move sequence failed', props<{ error: any }>());

export const sequenceLogs = createAction('[Sequences] Sequence logs', props<{ sequence: SequenceDTO }>());

export const saveSequence = createAction('[Sequences] Save sequence', props<{ sequence: SequenceDTO }>());
export const saveSequenceCompleted = createAction('[Sequences] Save sequence completed', props<{ sequence: SequenceDTO }>());
export const saveSequenceFailed = createAction('[Sequences] Save sequence failed', props<{ error: any }>());

export const deleteSequence = createAction('[Sequences] Delete sequence', props<{ sequence: SequenceDTO }>());
export const deleteSequenceCompleted = createAction('[Sequences] Delete sequence completed', props<{ sequence: SequenceDTO }>());
export const deleteSequenceCancelled = createAction('[Sequences] Delete sequence cancelled');
export const deleteSequenceFailed = createAction('[Sequences] Delete sequence failed', props<{ error: any }>());

export const backToClient = createAction('[Sequences] Back to client', props<{ clientId: number }>());
