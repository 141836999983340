import { createAction, props } from '@ngrx/store';

import { Automation, AutomationDTO, AutomationFilters } from '../../commons/models/automation.model';

export const loadAutomations = createAction('[Automations] Load automations', props<{ intentId?: number, page: number, perPage: number, order?: string, direction?: string, filters?: AutomationFilters }>());
export const loadAutomationsCompleted = createAction('[Automations] Load automations Completed', props<{ automations: AutomationDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: AutomationFilters }>());
export const loadAutomationsFailed = createAction('[Automations] Load automations Failed', props<{ error: any }>());

export const selectAutomation = createAction('[Automation] Select automation', props<{ automation: Automation }>());
export const automationDialogOpened = createAction('[Automation] Detail dialog opened', props<{ dialogId: string }>());
export const closeAutomationDialog = createAction('[Automation] Close detail dialog');

export const changePage = createAction('[Automation] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Automation] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Automation] Change filters', props<{ filters: AutomationFilters }>());