export function matchAll(re: RegExp, str: string) {
    let match: any;
    const matches = []

    while (match = re.exec(str)) {
        // add all matched groups
        matches.push(...match.slice(1))
    }

    return matches
}

export const isValidUrl = (urlString: string): boolean => {
    try {
        return Boolean(new URL(urlString));
    }
    catch (e) {
        return false;
    }
}

export function getUrlExtension(url: string): string {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}