import { createAction, props } from '@ngrx/store';
import {
  Filter,
  OAuthDTO,
  SheetDTO,
  SheetTemplate,
  SheetTemplateDTO,
  SheetTestDTO,
} from 'src/app/commons/models/sheets.model';

export const load = createAction('[Sheets] authenticate');
export const loadCompleted = createAction('[Sheets] authenticate Completed', props<{ authUrl?: string, email?: string }>());
export const loadFailed = createAction('[Sheets] authenticate Failed', props<{ error: any }>());

export const authenticateCode = createAction('[Sheets] Authenticate on sheets', props<{ id: number, code: string }>());
export const authenticateCodeCompleted = createAction('[Sheets] Authenticate on sheets Completed');
export const authenticateCodeFailed = createAction('[Sheets] Authenticate on sheets Failed', props<{ error: any }>());

export const loadToken = createAction('[Sheets] Load token');
export const loadTokenCompleted = createAction('[Sheets] Load token Completed', props<OAuthDTO>());
export const loadTokenFailed = createAction('[Sheets] Load token Failed', props<{ error: any }>());

export const loadSheets = createAction('[Sheets] Load sheets');
export const loadSheetsCompleted = createAction('[Sheets] Load sheets Completed', props<{ sheets: SheetDTO[] }>());
export const loadSheetsFailed = createAction('[Sheets] Load sheets Failed', props<{ error: any }>());

export const loadFilters = createAction('[Sheets] Load sheet filters', props<{ sheet: SheetDTO }>());
export const loadFiltersCompleted = createAction('[Sheets] Load sheet filters Completed', props<{ filters: Filter[] }>());
export const loadFiltersFailed = createAction('[Sheets] Load sheet filters Failed', props<{ error: any }>());

export const readSheet = createAction('[Sheets] Read sheet', props<{ sheet: SheetDTO }>());
export const readSheetCompleted = createAction('[Sheets] Read sheet Completed', props<{ data: string[][] }>());
export const readSheetFailed = createAction('[Sheets] Read sheet Failed', props<{ error: any }>());

export const openSheetDialog = createAction('[Sheets] Open sheet dialog');
export const sheetDialogOpened = createAction('[Sheets] Sheet dialog opened', props<{ dialogId: string }>());
export const closeSheetDialog = createAction('[Sheets] Close sheet dialog');

export const openSheetTestDialog = createAction('[Sheets] Open sheet test dialog', props<{ fields: string[], template: SheetTemplate }>());
export const sheetTestDialogOpened = createAction('[Sheets] Sheet test dialog opened', props<{ dialogId: string }>());
export const closeSheetTestDialog = createAction('[Sheets] Close sheet test dialog');
export const sendTest = createAction('[Sheets] Send test', props<{ test: SheetTestDTO }>());
export const sendTestCompleted = createAction('[Sheets] Send test Completed');
export const sendTestFailed = createAction('[Sheets] Send test Failed', props<{ error: any }>());

export const openSheetEditDialog = createAction('[Sheets] Open edit dialog', props<{ header: string[], clientId: string }>());
export const sheetEditDialogOpened = createAction('[Sheets] Edit dialog opened', props<{ dialogId: string }>());
export const closeSheetEditDialog = createAction('[Sheets] Close edit dialog');

export const sendMessages = createAction('[Sheets] Send messages', props<{ template: SheetTemplateDTO }>());
export const sendMessagesCompleted = createAction('[Sheets] Send messages Completed', props<{ delay: boolean }>());
export const sendMessagesCanceled = createAction('[Sheets] Send messages Canceled');
export const sendMessagesFailed = createAction('[Sheets] Send messages Failed', props<{ error: any }>());

export const openFrame = createAction('[Sheets] Open frame', props<{ url: string }>());
export const frameClosed = createAction('[Sheets] Frame closed');

export const disconnect = createAction('[Sheets] Disconnect');
export const disconnectCompleted = createAction('[Sheets] Disconnect Completed');
export const disconnectFailed = createAction('[Sheets] Disconnect Failed', props<{ error: any }>());
export const disconnectCanceled = createAction('[Sheets] Disconnect Cancelled');
