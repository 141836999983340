import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { Template, TemplateFilters } from 'src/app/commons/models/template.model';

import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type TemplateColumn = "id" | "nome" | "attivo" | "messaggiInviati" | "delay" | "provider" | "actions";

@Component({
  selector: 'template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  templates: Observable<Template[]>;

  @Input()
  defaultFilters: TemplateFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: TemplateColumn[] = ["id", "nome", "attivo", "delay", "actions"];

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<TemplateFilters> = new EventEmitter<TemplateFilters>();

  @Output()
  onSelectTemplate: EventEmitter<Template> = new EventEmitter<Template>();
  @Output()
  onAddTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditTemplate: EventEmitter<Template> = new EventEmitter<Template>();
  @Output()
  onCloneTemplate: EventEmitter<Template> = new EventEmitter<Template>();
  @Output()
  onDeleteTemplate: EventEmitter<Template> = new EventEmitter<Template>();
  @Output()
  onChangeStatusTemplate: EventEmitter<Template> = new EventEmitter<Template>();
  @Output()
  onTestTemplate: EventEmitter<Template> = new EventEmitter<Template>();

  constructor() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addTemplate() {
    this.onAddTemplate.emit();
  }

  editTemplate(template: Template) {
    this.onEditTemplate.emit(template);
  }

  selectTemplate(template: Template) {
    this.onSelectTemplate.emit(template);
  }

  cloneTemplate(template: Template) {
    this.onCloneTemplate.emit(template);
  }

  onFilter(filters: TemplateFilters) {
    this.paginator?.firstPage();
    this.onFilterChange.emit(filters);
  }

  testTemplate(template: Template) {
    this.onTestTemplate.emit(template);
  }

  changeStatusTemplate(template: Template) {
    let newTemplate = new Template(template.toDTO());
    newTemplate.attivo = !newTemplate.attivo;

    this.onChangeStatusTemplate.emit(newTemplate);
  }
}
