import { UntypedFormGroup } from "@angular/forms";

import { Base, BaseDTO } from "./base.model";

export type SessionType = "send_app" | "socket";

export interface SessionDTO extends BaseDTO {
  client_id: number;
  tipologia: SessionType;
  access_token?: string;
  instance_id?: string;
  status: boolean;
  status_last_check?: string;
}

export class Session extends Base {
  clientId: number;
  tipologia: SessionType;
  accessToken?: string;
  instanceId?: string;
  status: boolean;
  statusLastCheck?: Date;

  constructor(source: SessionDTO) {
    super(source);
    if (source) {
      this.clientId = source.client_id;
      this.tipologia = source.tipologia;
      this.accessToken = source.access_token;
      this.instanceId = source.instance_id;
      this.status = source.status;
      if (source.status_last_check)
        this.statusLastCheck = new Date(source.status_last_check);
    }
  }

  toDTO(): SessionDTO {
    let result: SessionDTO = <SessionDTO>super.toDTO();
    result.client_id = this.clientId;
    result.tipologia = this.tipologia;
    result.access_token = this.accessToken;
    result.instance_id = this.instanceId;

    return result;
  }

  static fromFormGroup(
    formGroup: UntypedFormGroup,
    original?: Session
  ): Session {
    const formModel = formGroup.getRawValue();
    let client: Session = new Session(null);
    client.clientId = formModel.clientId;
    client.tipologia = formModel.tipologia;
    client.accessToken = formModel.accessToken;
    client.instanceId = formModel.instanceId;

    if (original) {
      client.id = original.id;
    }
    return client;
  }
}
