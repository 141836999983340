<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    Filtra
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Telefono</mat-label>
        <input type="text" matInput formControlName="phone">
      </mat-form-field>
      <mat-form-field class="u-full-width" *showFor="['admin', 'partner']">
        <mat-label>Utente</mat-label>
        <app-user-field formControlName="user"></app-user-field>
      </mat-form-field>
      <span class="u-flex-full">
        <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
          (click)="applyFilters()">Filtra</button>
        <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>
