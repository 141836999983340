import { Action, createReducer, on } from '@ngrx/store';

import * as LibraryActions from '../actions/library.actions';

export interface LibraryState {
  files: string[];
  libraryDialogId: string;
};

const initialState: LibraryState = {
  files: null,
  libraryDialogId: null
};

const libraryReducer = createReducer(
  initialState,
  on(LibraryActions.listFilesCompleted, (state, { files }) => {
    return { ...state, files }
  }),
  on(LibraryActions.libaryDialogOpened, (state, { dialogId }) => {
    return { ...state, libraryDialogId: dialogId };
  }),
);

export function reducer(state: LibraryState | undefined, action: Action) {
  return libraryReducer(state, action);
}
