import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { filter, map, Observable, startWith, Subject } from 'rxjs';
import { Client } from 'src/app/commons/models/client.model';
import { FAIL_REASONS, MessageFilters, SOURCE_TYPES, SourceType } from 'src/app/commons/models/message.model';

import { MESSAGE_STATUSES, MessageStatus } from './../../../commons/models/message.model';

@Component({
  selector: 'message-filters',
  templateUrl: './message-filters.component.html',
  styleUrls: ['./message-filters.component.scss']
})
export class MessageFiltersComponent {
  private unsubscribe$ = new Subject<void>();

  @Output()
  onFilter: EventEmitter<MessageFilters> = new EventEmitter<MessageFilters>();

  private _defaultFilters: MessageFilters;
  filtersForm: UntypedFormGroup;

  @Input()
  clients: Client[];

  filteredClients: Observable<Client[]>;
  messageStatuses: MessageStatus[] = MESSAGE_STATUSES;
  sourceTypes: SourceType[] = SOURCE_TYPES;

  failReasons: string[] = FAIL_REASONS;

  constructor(private fb: UntypedFormBuilder) {
    this._createForm();

    this.filteredClients = this.filtersForm.get('client').valueChanges.pipe(
      startWith(''),
      filter(value => typeof value === 'string'),
      map(value => this._filterClients(value || '')),
    );
  }

  private _filterClients(value: string): Client[] {
    const filterValue = value.toLowerCase();

    return this.clients?.filter(client => client.nome.toLowerCase().includes(filterValue)).sort((a, b) => a.nome.localeCompare(b.nome));
  }

  private _createForm() {
    let group = {
      search: "",
      createdAtFrom: "",
      createdAtTo: "",
      sentAtFrom: "",
      sentAtTo: "",
      ids: [],
      phone: "",
      statuses: "",
      client: "",
      types: "",
      failReason: "",
      externalId: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getMessageFilters());
  }

  private _getMessageFilters(): MessageFilters {
    let filters: MessageFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.createdAtFrom = values.createdAtFrom;
      filters.createdAtTo = values.createdAtTo;
      filters.sentAtFrom = values.sentAtFrom;
      filters.sentAtTo = values.sentAtTo;
      filters.phone = values.phone;
      filters.statuses = values.statuses;
      // filters.clientId = values.client?.id;
      filters.sessionId = values.client?.sessionId;
      filters.types = values.types;
      filters.failReason = values.failReason;
      filters.externalId = values.externalId;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      createdAtFrom: "",
      createdAtTo: "",
      sentAtFrom: "",
      sentAtTo: "",
      ids: [],
      phone: "",
      statuses: [],
      clientId: "",
      types: [],
      failReason: "",
      externalId: "",
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): MessageFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: MessageFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          ids: this.defaultFilters.ids,
          createdAtFrom: this.defaultFilters.createdAtFrom,
          createdAtTo: this.defaultFilters.createdAtTo,
          sentAtFrom: this.defaultFilters.sentAtFrom,
          sentAtTo: this.defaultFilters.sentAtTo,
          phone: this.defaultFilters.phone,
          statuses: this.defaultFilters.statuses,
          clientId: this.defaultFilters.clientId,
          types: this.defaultFilters.types,
          failReason: this.defaultFilters.failReason,
          externalId: this.defaultFilters.externalId
        })
      }
    }
  }

  displayClient(client: Client): string {
    return client?.nome;
  }
}
