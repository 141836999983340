<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="sequence">Modifica sequenza: {{ sequence.name }}</span>
    <span *ngIf="!sequence">Nuova sequenza</span>
  </span>
  <span *ngIf="sequence">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteSequence()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="sequenceForm" novalidate>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Nome" formControlName="name" type="text" />
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>

    <mat-form-field class="u-full-width">
      <input matInput placeholder="Campo telefono" formControlName="phoneField" type="text" />
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="active">Attiva</mat-checkbox>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()" [disabled]="sequenceForm.pristine">
      Reset
    </button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="sequenceForm.pristine || !sequenceForm.valid">
      Salva
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Chiudi
  </button>
</mat-dialog-actions>