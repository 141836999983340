<ng-container *ngIf="message">
  <header mat-dialog-title class="u-flex-full">
    <span *ngIf="message">Dettagli messaggio {{message.externalId}}</span>
  </header>

  <mat-dialog-content>
    <table class="table table-message">
      <tr>
        <td><span class="label">ID interno</span></td>
        <td>{{ message.id }}</td>
      </tr>

      <tr>
        <td><span class="label">Tipologia</span></td>
        <td>{{ message.sourceType |sourceType }}</td>
      </tr>

      <tr>
        <td><span class="label">Ricezione</span></td>
        <td>{{ message.createdAt | dateFormat }}</td>
      </tr>

      <tr>
        <td><span class="label">Invio</span></td>
        <td>{{ message.processDate | dateFormat }}</td>
      </tr>

      <tr>
        <td><span class="label">Telefono</span></td>
        <td>{{ message.phone }}</td>
      </tr>

      <tr>
        <td><span class="label">Stato</span></td>
        <td>{{ message.messageStatus | status }}</td>
      </tr>

      <tr *showFor="['admin', 'partner']">
        <td><span class="label">Cliente</span></td>
        <td>
          <ng-container *ngIf="message.nomeCliente">
            <a [href]="clientURL" target="_blank">
              {{ message.nomeCliente }}
            </a>
          </ng-container>
        </td>
      </tr>

      <tr>
        <td><span class="label">Template</span></td>
        <td>
          <ng-container *ngIf="message.sequenceId">
            <a [href]="sequenceURL" target="_blank">
              {{ message.nomeTemplate }}
            </a>
          </ng-container>
        </td>
      </tr>

      <tr>
        <td><span class="label">Provider</span></td>
        <td>{{ message.providerTemplate }}</td>
      </tr>

      <tr>
        <td><span class="label">Contenuto</span></td>
        <td>
          <div [innerText]="message.content"></div>
        </td>
      </tr>

      <tr>
        <td><span class="label">Media URL</span></td>
        <td>
          <a *ngIf="!!message.mediaUrl" target="_blank" [href]="message.mediaUrl">{{ message.mediaUrl }}</a>
        </td>
      </tr>

      <tr>
        <td><span class="label">Nome Media</span></td>
        <td>{{ message.mediaNome }}</td>
      </tr>

      <tr>
        <td><span class="label">Richiesta</span></td>
        <td>
          <pre *ngIf="!!message.requestBody" [innerHTML]="message.requestBody | prettyjson"></pre>
        </td>
      </tr>

      <tr>
        <td><span class="label">Risposta</span></td>
        <td>{{ message.responseMessage }}</td>
      </tr>

      <tr>
        <td><span class="label">Dettaglio risposta</span></td>
        <td>
          <pre *ngIf="!!message.responseBody" [innerHTML]=" message.responseBody | prettyjson"></pre>
        </td>
      </tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions class="u-flex-full">
    <span></span>
    <button mat-raised-button color="basic" type="button" (click)="close()">
      Chiudi
    </button>
  </mat-dialog-actions>
</ng-container>