import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Session, SessionDTO } from './session.model';
import { User, UserDTO } from './user.model';

export interface ClientFilters {
  search?: string;
  ids?: number[];
}

export interface ClientDTO extends BaseDTO {
  user_id: number;
  user: UserDTO;
  nome: string;
  telefono: string;
  sequences_count: number;
  sessions: SessionDTO[]
  recived_messages_hook?: string;
  api_key?: string;
}

export class Client extends Base {
  user: User;
  userId: number;
  nome: string;
  telefono: string;
  messaggiAttivi: number;
  sessions: Session[];
  recivedMessagesHook?: string;
  apiKey?: string;

  constructor(source: ClientDTO) {
    super(source);
    if (source) {
      if (source.user) {
        this.user = new User(source.user);
        this.addLoadedRelation('user');
      }
      this.userId = source.user_id;
      this.nome = source.nome;
      this.telefono = source.telefono;
      this.messaggiAttivi = source.sequences_count;
      if (source.sessions) {
        this.sessions = source.sessions.map(s => new Session(s));
        this.addLoadedRelation('sessions');
      }
      this.recivedMessagesHook = source.recived_messages_hook;
      this.apiKey = source.api_key;
    }
  }

  toDTO(): ClientDTO {
    let result: ClientDTO = <ClientDTO>super.toDTO();
    result.user = this.user ? this.user.toDTO() : null;
    result.user_id = this.userId;
    result.nome = this.nome;
    result.telefono = this.telefono;
    if (this.sessions) {
      result.sessions = this.sessions.map(s => s.toDTO());
    }
    result.recived_messages_hook = this.recivedMessagesHook;

    return result;
  }


  static fromFormGroup(formGroup: AbstractControl, original?: Client) {
    const formModel = formGroup.value;
    let client: Client = new Client(null);
    client.userId = formModel.userId;
    client.nome = formModel.nome;
    client.telefono = formModel.telefono;
    client.sessions = formModel.sessions;
    client.recivedMessagesHook = formModel.recivedMessagesHook;

    if (original) {
      client.id = original.id;
    }
    return client;
  }

  get online(): boolean {
    return this.sessions[0]?.status
  }

  get sessionId(): number {
    return this.sessions[0]?.id
  }

}
