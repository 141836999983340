import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TemplateFilters } from 'src/app/commons/models/template.model';


@Component({
  selector: 'template-filters',
  templateUrl: './template-filters.component.html',
  styleUrls: ['./template-filters.component.scss']
})
export class TemplateFiltersComponent {

  @Output()
  onFilter: EventEmitter<TemplateFilters> = new EventEmitter<TemplateFilters>();

  private _defaultFilters: TemplateFilters;
  filtersForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      showInactive: false,
      ids: [],
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getTemplateFilters());
  }

  private _getTemplateFilters(): TemplateFilters {
    let filters: TemplateFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.showInactive = values.showInactive;
      filters.ids = values.ids;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      showInactive: true,
      ids: [],

    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): TemplateFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: TemplateFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          ids: this.defaultFilters.ids,
          showInactive: this.defaultFilters.showInactive
        })
      }
    }
  }
}