<app-user-filters [hidden]="hideFilters" (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-user-filters>
<mat-card class="u-center-text" *ngIf="!(users | async).length">
  <h4>Nessun utente trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addUser()">Aggiungi</button>
</mat-card>
<div [hidden]="!(users | async).length">
  <table mat-table matSort [dataSource]="filteredUsers$">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let user">{{ user.id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        <div>
          Nome
        </div>
        <mat-form-field>
          <input matInput [formControl]="nameFilterCtrl" type="text" />
          <button *ngIf="nameFilterCtrl.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="nameFilterCtrl.reset()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let user">
        <span *ngIf="!user.active" class="badge">Non Attivo</span>
        <ng-container *showFor="['admin']">
          <span *ngIf="user.partnerName" class="partner-badge"
            [style]="'background-color: ' + user.partnerColor">{{user.partnerName}}</span>
        </ng-container>
        {{ user.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let user">
        {{ user.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="partner">
      <th mat-header-cell *matHeaderCellDef>Partner</th>
      <td mat-cell *matCellDef="let user">
        {{ user.partnerName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Ruolo</th>
      <td mat-cell *matCellDef="let user">
        {{ user.role | userRole }}
        <span *ngIf="user.role === 'partner'">[{{user.partnerUsers}}]</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="primary"
          (click)="$event.stopPropagation(); addUser()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>

      <td mat-cell *matCellDef="let user">
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectUser(user)">
          Seleziona
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="editUser(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[2]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>