import { Action, createReducer, on } from '@ngrx/store';
import { ClientDTO } from 'src/app/commons/models/client.model';
import { SequenceDTO, SequenceFilters } from 'src/app/commons/models/sequence.model';

import * as ClientActions from '../actions/client.actions';
import * as SequenceActions from '../actions/sequence.actions';

export interface SequenceState {
  list: SequenceDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: SequenceFilters,
  dialogId: string,
  sequence: SequenceDTO,
  sessionId: number,
  clients: ClientDTO[]
};

const initialState: SequenceState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  sequence: null,
  sessionId: null,
  clients: null
};

const sequenceReducer = createReducer(
  initialState,
  on(ClientActions.loadClientCompleted, (state, { client }) => {
    return { ...state, sessionId: client?.sessions?.length > 0 ? client.sessions[0]?.id : null };
  }),
  on(SequenceActions.loadSequencesCompleted, (state, { sequences, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, list: sequences, currentPage, total, perPage, order, direction, filters };
  }),
  on(SequenceActions.saveSequenceCompleted, (state, { sequence }) => {
    return { ...state, sequence };
  }),
  on(SequenceActions.sequenceDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(SequenceActions.loadAllClientsCompleted, (state, { clients }) => {
    return { ...state, clients };
  }),
  on(SequenceActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(SequenceActions.loadSequenceCompleted, (state, { sequence }) => {
    return { ...state, sequence };
  }),
  on(SequenceActions.saveSequenceCompleted, (state, { sequence }) => {
    return { ...state, sequence };
  }),
  on(SequenceActions.selectSequence, (state, { sequence }) => {
    return { ...state, sequence: sequence, sessionId: sequence.session_id };
  }),
);

export function reducer(state: SequenceState | undefined, action: Action) {
  return sequenceReducer(state, action);
}
