import { Action, createReducer, on } from '@ngrx/store';
import { AutomationDTO, AutomationFilters } from 'src/app/commons/models/automation.model';
import { defaultFilters } from 'src/app/commons/models/template.model';
import * as AutomationActions from '../actions/automation.actions';
import * as IntentActions from '../actions/intent.actions';

export interface AutomationState {
  list: AutomationDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: AutomationFilters,
  intentId: number,
  dialogId: string
};

const initialState: AutomationState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: defaultFilters,
  intentId: null,
  dialogId: null,
};

const messageReducer = createReducer(
  initialState,
  on(AutomationActions.loadAutomations, (state, { intentId }) => {
    return { ...state, intentId };
  }),
  on(AutomationActions.loadAutomationsCompleted, (state, { automations, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, list: automations, currentPage, total, perPage, order, direction, filters };
  }),
  on(AutomationActions.automationDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(AutomationActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(IntentActions.intentLogs, (state, { intent }) => {
    return { ...state, filters: { ...state.filters, ids: [...state.filters.ids, intent.id] } };
  }),
);

export function reducer(state: AutomationState | undefined, action: Action) {
  return messageReducer(state, action);
}