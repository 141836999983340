import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AlertService } from 'src/app/commons/services/alert.service';
import { PRIMARY_COLOR } from 'src/app/constants/theme.constants';

import { LaravelUserService } from '../../commons/services/backend/laravel-user.service';
import * as AuthActions from '../actions/auth.actions';
import { AppState } from '../reducers';
import * as AuthSelectors from '../selectors/auth.selectors';

@Injectable()
export class AuthEffects {

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      mergeMap(({ username, password }) =>
        this.laravelUserService.login(username, password)
          .pipe(
            map(result => AuthActions.loginCompleted({ currentUser: result.user, token: result.success_token })),
            catchError(error => of(AuthActions.loginFailed({ error }))))
      ))
  }
  );

  afterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginCompleted),
      tap(() => {
        this.alertService.showConfirmMessage(`Login effettuato con successo`);
      }),
      map(() => AuthActions.loadCurrentUser({ goToHome: true }))
    )
  }
  );

  goToHome$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadCurrentUserCompleted),
      filter(({ goToHome }) => !!goToHome),
      tap(() => {
        this.router.navigate(['/']);
      }),
    )
  }, { dispatch: false }
  );

  loginError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginFailed),
      tap((action) => this.alertService.showErrorMessage(`Errore durante il login`, action.error))
    )
  }, { dispatch: false }
  );

  directLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.directLogin),
      map(() => AuthActions.loadCurrentUser({ goToHome: true })),
    )
  );

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      withLatestFrom(this.store$.select(AuthSelectors.getRedirectOnLogout)),
      switchMap(([{ showConfirm }, redirectUrl]) => {
        if (showConfirm) {
          return this.alertService.showConfirmDialog('Conferma', `Sei sicuro di voler effettuare il logout?`)
            //mrosetti - Nothing to do so far, just relaunch logoutCompleted action
            .pipe(
              map((confirm) => {
                return confirm ? AuthActions.logoutCompleted({ redirectUrl }) : AuthActions.logoutCancelled();
              })
            )
        } else {
          return of(AuthActions.logoutCompleted({ redirectUrl }));
        }
      }))
  }
  );

  afterLogout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logoutCompleted),
      filter(() => !window.location.pathname.startsWith("/code")),
      tap(({ redirectUrl }) => {
        if (redirectUrl) {
          window.location.href = redirectUrl;
          top.location.reload();
        } else {
          this.router.navigate(['login']);
        }
      }),
      tap(() => this.alertService.showConfirmMessage(`Logout effettuato con successo`))
    )
  },
    { dispatch: false }
  );

  currentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadCurrentUser),
      switchMap(({ goToHome }) =>
        this.laravelUserService.getCurrentUser()
          .pipe(
            map((currentUser) => AuthActions.loadCurrentUserCompleted({ currentUser, goToHome })),
            catchError(error => of(AuthActions.loadCurrentUserFailed({ error }))))
      )
    )
  });

  showFailedMessagesWarning$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadCurrentUserCompleted),
      tap(({ currentUser }) => {
        if (currentUser.failed_messages) {
          this.alertService.showErrorMessage('Attenzione', `Sono risultati falliti ${currentUser.failed_messages} messaggi dall'ultima connessione. Controllare la pagina "Storico Messaggi" per maggiori dettagli`);
        }
      })
    )
  }, { dispatch: false });

  setUserColor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadCurrentUserCompleted),
      tap((action) => {
        if (action.currentUser.color) {
          document.documentElement.style.setProperty('--theme-primary', action.currentUser.color);
        }
      })
    )
  }, { dispatch: false })

  resetColor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logoutCompleted),
      tap(() => {
        document.documentElement.style.setProperty('--theme-primary', PRIMARY_COLOR);
      })
    )
  }, { dispatch: false })


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private laravelUserService: LaravelUserService,
    private alertService: AlertService,
    private router: Router
  ) { }
}
