<mat-card class="mb-20">
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="form-text" *ngIf="false">
        <mat-label>Ricerca libera</mat-label>
        <input type="text" matInput formControlName="search" />
      </mat-form-field>

      <mat-checkbox matInput color="primary" formControlName="showInactive" class="form-check">
        <mat-label>Mostra anche inatttivi</mat-label>
      </mat-checkbox>

      <span class="u-flex-full">
        <button mat-raised-button [disabled]="filtersForm.pristine" color="primary" (click)="applyFilters()">
          Filtra
        </button>
        <button mat-icon-button [disabled]="filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-card>