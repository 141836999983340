import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { User } from "src/app/commons/models/user.model";
import { map, takeUntil } from "rxjs/operators";
import { AppState } from 'src/app/store/reducers';
import * as SidebarActions from 'src/app/store/actions/sidebar.actions';
import * as AuthSelectors from "src/app/store/selectors/auth.selectors";
import * as UserActions from "src/app/store/actions/user.actions";
import { select, Store } from "@ngrx/store";
import { logout } from "src/app/store/actions/auth.actions";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-topnav",
  templateUrl: "./topnav.component.html",
  styleUrls: ["./topnav.component.scss"],
})
export class TopnavComponent implements OnInit {
  url: string;
  private unsubscribe$ = new Subject<void>();
  currentUser: User;
  logo = "/assets/images/logo.png";

  constructor(private store$: Store<AppState>, private router: Router) { }

  ngOnInit() {
    this.url = location.pathname;

    this.store$
      .pipe(
        select(AuthSelectors.getCurrentUser),
        takeUntil(this.unsubscribe$),
        map((dto) => (dto ? new User(dto) : null))
      )
      .subscribe((user) => {
        this.currentUser = user
        if (this.currentUser?.logoPath) {
          this.logo = `${environment.baseUrl}/${this.currentUser.logoPath}`;
        }
      });
  }

  get shouldHide(): boolean {
    return this.url.startsWith("/code");
  }

  toggleSidebar() {
    this.store$.dispatch(SidebarActions.sidebarToggle());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout() {
    this.store$.dispatch(logout({ showConfirm: true }));
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector("body");
    return dom.classList.contains("push-right");
  }

  changePassword() {
    if (this.currentUser) {
      this.store$.dispatch(
        UserActions.changeUserPassword({ user: this.currentUser.toDTO() })
      );
    }
  }

  showPaymentInfo() {
    this.store$.dispatch(
      UserActions.showPaymentInfo({ user: this.currentUser.toDTO() })
    );
  }
}