import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LaravelLibraryService {

  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      upload: environment.baseUrl + "/api/library/upload",
      listFiles: environment.baseUrl + "/api/library/list_files",
      deleteFile: environment.baseUrl + "/api/library/delete_file",
      fileUrl: environment.baseUrl + "/api/library/file_url",
    };
  }

  public uploadFile(id: number, content: any, temp: boolean = false): Observable<string> {
    let formData: FormData = new FormData();
    formData.append("upload", content);
    if(temp) {
      formData.append("temp", "true");
    }
    return this.httpClient.post<string>(`${this.ROUTES.upload}/${id}`, formData);
  }

  public listFiles(id: number, temp: boolean = false): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.ROUTES.listFiles}/${id}`, {
      params: new HttpParams({
        fromObject: {temp}
      })
    });
  }

  public removeFile(id: number, filename: string, temp: boolean = false): Observable<any> {
    return this.httpClient.delete(`${this.ROUTES.deleteFile}/${id}`, {
      params: new HttpParams({
        fromObject: {filename, temp}
      })
    });
  }

  public fileUrl(id: number, filename: string, temp: boolean = false): Observable<string> {
    return this.httpClient.get<string>(`${this.ROUTES.fileUrl}/${id}`, {
      params: new HttpParams({
        fromObject: {filename, temp}
      })
    });
  }
}
