import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Message } from 'src/app/commons/models/message.model';
import * as MessageActions from 'src/app/store/actions/message.actions';
import { AppState } from 'src/app/store/reducers';


@Component({
  selector: 'app-message-modal',
  templateUrl: './select-date-modal.component.html',
  styleUrls: ['./select-date-modal.component.scss']
})
export class SelectDateModalComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  public message: Message;
  public messages: number[];

  dateController: UntypedFormControl = new UntypedFormControl("", [Validators.required]);

  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data && this.data.message) {
      this.message = new Message(this.data.message);
    }
    if (this.data && this.data.messages) {
      this.messages = this.data.messages;
    }
    this.reset();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  confirm() {
    if (this.message && this.dateController.valid) {
      this.store$.dispatch(MessageActions.rescheduleMessage({ message: this.message.toDTO(), date: this.dateController.value }))
    }
    if (this.messages && this.dateController.valid) {
      this.store$.dispatch(MessageActions.rescheduleMessages({ messages: this.messages, date: this.dateController.value }))
    }
  }

  reset() {
    this.dateController.reset();
    this.dateController.patchValue(this.message?.processDate);
  }

  close() {
    this.store$.dispatch(MessageActions.closeMessageDialog());
  }
}
