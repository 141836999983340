import { Action, createReducer, on } from '@ngrx/store';
import { IntentDTO, IntentFilters } from 'src/app/commons/models/intent.model';
import * as IntentActions from '../actions/intent.actions';
import * as ClientActions from '../actions/client.actions';

export interface IntentState {
    list: IntentDTO[],
    total: number,
    currentPage: number,
    perPage: number,
    order: string,
    direction: string,
    filters: IntentFilters,
    dialogId: string,
    intent: IntentDTO,
    clientId: number,
    testDialogId: string
};

const initialState: IntentState = {
    list: [],
    total: 0,
    currentPage: 1,
    perPage: 25,
    order: null,
    direction: null,
    filters: null,
    dialogId: null,
    intent: null,
    clientId: null,
    testDialogId: null
};

const clientReducer = createReducer(
    initialState,
    on(ClientActions.loadClient, (state, { id }) => {
        return { ...state, clientId: id };
    }),
    on(IntentActions.setClient, (state, { clientId }) => {
        return { ...state, clientId };
    }),
    on(ClientActions.selectClient, (state, { client }) => {
        return { ...state, clientId: client.id };
    }),
    on(IntentActions.loadIntentsCompleted, (state, { intents, currentPage, total, perPage, order, direction, filters }) => {
        return { ...state, list: intents, currentPage, total, perPage, order, direction, filters };
    }),
    on(IntentActions.saveIntentCompleted, (state, { intent }) => {
        return { ...state, intent };
    }),
    on(IntentActions.editDialogOpened, (state, { dialogId }) => {
        return { ...state, dialogId };
    }),
    on(IntentActions.changeFilters, (state, { filters }) => {
        return { ...state, currentPage: 1, filters };
    }),
    on(IntentActions.testDialogOpened, (state, { testDialogId }) => {
        return { ...state, testDialogId }
    })
);

export function reducer(state: IntentState | undefined, action: Action) {
    return clientReducer(state, action);
}