<ng-container *ngIf="message || messages">
  <header mat-dialog-title class="u-flex-full">
    <span *ngIf="message || messages">Scegli nuova data di invio</span>
  </header>

  <mat-dialog-content>
    <mat-form-field class="u-full-width">
      <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Nuova data di invio"
        [formControl]="dateController" />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #picker1 color="primary">
      </ngx-mat-datetime-picker>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="u-flex-full">
    <span>
      <button mat-raised-button color="basic" type="reset" (click)="reset()" [disabled]="dateController.pristine">
        Reset
      </button>
      <button mat-raised-button color="primary" (click)="confirm()"
        [disabled]="dateController.pristine || !dateController.valid">
        Salva
      </button>
      <span class="fill-remaining"></span>
    </span>
    <button mat-raised-button color="basic" type="button" (click)="close()">
      Chiudi
    </button>
  </mat-dialog-actions>
</ng-container>