import { Pipe, PipeTransform } from '@angular/core';
import { SourceType } from 'src/app/commons/models/message.model';

@Pipe({
  name: 'sourceType'
})
export class SourceTypePipe implements PipeTransform {
  transform(value: SourceType): unknown {
    switch (value) {
      case 'SINGLE':
        return "Messaggio singolo";
      case 'AUTOMATION':
        return "Chatbot";
      case 'MASSIVE':
        return "Invio massivo";
      case 'SEQUENCE':
        return "Sequenza"
      default:
        return "";
    }
  }

}
