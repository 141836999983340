<template-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters" class="margin-filters">
</template-filters>


<mat-card class="u-center-text no-message" *ngIf="!(templates | async).length">
  <h4>Nessun messaggio trovato</h4>
  <button mat-raised-button (click)="addTemplate()">Aggiungi</button>
</mat-card>

<div [hidden]="!(templates | async).length">
  <mat-card class="u-center-text template-list">
    <mat-card-title class="card-title">
      <span>
        Messaggi
      </span>
      <button mat-button color="primary" (click)="addTemplate()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-card-title>

    <table mat-table matSort [dataSource]="templates">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
        <td mat-cell *matCellDef="let template">{{ template.id }}</td>
      </ng-container>

      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let template">
          <button mat-button (click)="editTemplate(template)">{{ template.nome }} </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="attivo">
        <th mat-header-cell *matHeaderCellDef>Attivo</th>
        <td mat-cell *matCellDef="let template">
          <mat-slide-toggle color="primary" [checked]="template.attivo" (change)="changeStatusTemplate(template)">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="messaggiInviati">
        <th mat-header-cell *matHeaderCellDef>Messaggi Inviati</th>
        <td mat-cell *matCellDef="let template">
          {{ template.messaggiInviati }}
        </td>
      </ng-container>

      <ng-container matColumnDef="provider">
        <th mat-header-cell *matHeaderCellDef>Provider</th>
        <td mat-cell *matCellDef="let template">
          {{ template.provider | templateProvider }}
        </td>
      </ng-container>

      <ng-container matColumnDef="delay">
        <th mat-header-cell *matHeaderCellDef>Pianificazione</th>
        <td mat-cell *matCellDef="let template">
          {{ template.delay | delay }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
        </th>

        <td mat-cell *matCellDef="let template">
          <ng-container>
            <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
              <button mat-menu-item (click)="editTemplate(template)">
                <mat-icon>create</mat-icon>
                Modifica
              </button>
              <button mat-menu-item (click)="cloneTemplate(template)">
                <mat-icon>file_copy</mat-icon>
                Duplica
              </button>
              <button mat-menu-item *ngIf="template.attivo" (click)="testTemplate(template)">
                <mat-icon>code</mat-icon>
                Test
              </button>
            </mat-menu>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
      showFirstLastButtons>
    </mat-paginator>
  </mat-card>
</div>