import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Message } from 'src/app/commons/models/message.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as MessageActions from 'src/app/store/actions/message.actions';
import { AppState } from 'src/app/store/reducers';


@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  public message: Message;

  constructor(private store$: Store<AppState>, private alertService: AlertService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data) {
      this.message = this.data.message;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    this.store$.dispatch(MessageActions.closeMessageDialog());
  }

  get clientURL() {
    return `/client/${this.message.clientId}`;
  }

  get sequenceURL() {
    return `/sequence/${this.message.sequenceId}`;
  }
}