import { createAction, props } from '@ngrx/store';

export const listFiles = createAction('[Library] List files', props<{ sessionId?: number, temp: boolean }>());
export const listFilesCompleted = createAction('[Library] List files Completed', props<{ files: string[] }>());
export const listFilesFailed = createAction('[Library] List files Failed', props<{ error: any }>());

export const uploadFile = createAction('[Library] Upload file', props<{ sessionId?: number, file: any, temp: boolean }>());
export const uploadFileCompleted = createAction('[Library] Upload file completed', props<{sessionId: number, path: string, temp: boolean }>());
export const uploadFileFailed = createAction('[Library] Upload file failed', props<{ error: any }>());

export const removeFile = createAction('[Library] Remove file', props<{ sessionId?: number, filename: string, temp: boolean }>());
export const removeFileCompleted = createAction('[Library] Remove file completed', props<{ sessionId: number, temp: boolean}>());
export const removeFileFailed = createAction('[Library] Remove file failed', props<{ error: any }>());

export const openLibrary = createAction('[Library] Open library dialog', props<{temp: boolean}>());
export const libaryDialogOpened = createAction('[Library] Library dialog opened', props<{ dialogId: string }>());
export const closeLibraryDialog = createAction('[Library] Close library dialog');

export const resolveUrl = createAction('[Library] Resolve url', props<{ sessionId?: number, filename: string, temp: boolean }>());
export const resolveUrlCompleted = createAction('[Library] Resolve url completed', props<{ sessionId: number, url: string}>());
export const resolveUrlFailed = createAction('[Library] Resolve url failed', props<{ error: any }>());

export const downloadFile = createAction('[Library] Download file', props<{ sessionId?: number, filename: string, temp: boolean }>());
export const downloadFileCompleted = createAction('[Library] Download file completed', props<{ filename: string, url: string}>());
export const downloadFileFailed = createAction('[Library] Download file failed', props<{ error: any }>());
