import { createAction, props } from '@ngrx/store';

import { Session, SessionDTO } from '../../commons/models/session.model';

export const selectSession = createAction('[Session] Select session id', props<{ session: Session }>());

export const loadScreenshot = createAction('[Session] Load screenshot', props<{ sessionId: number }>());
export const loadScreenshotCompleted = createAction('[Session] Load screenshot Completed', props<{ image: string }>());
export const loadScreenshotFailed = createAction('[Session] Load screenshot Failed', props<{ error: any }>());

export const sessionDialogOpened = createAction('[Sessions] Detail dialog opened', props<{ dialogId: string }>());
export const closeSessionDialog = createAction('[Sessions] Close detail dialog');

export const startSession = createAction('[Code] Start session', props<{ sessionId: number, apiKey: string }>());
export const startSessionCompleted = createAction('[Code] Start session Completed', props<{ filename: string }>());
export const startSessionFailed = createAction('[Code] Start session Failed', props<{ error: any }>());

export const loadCode = createAction('[Code] Load qrcode', props<{ sessionId: number, apiKey: string, silent?: boolean }>());
export const loadCodeCompleted = createAction('[Code] Load qrcode Completed', props<{ code: string }>());
export const loadCodeFailed = createAction('[Code] Load qrcode Failed', props<{ error: any }>());

export const loadStatus = createAction('[Code] Get status', props<{ sessionId: number, apiKey: string, silent?: boolean }>());
export const loadStatusCompleted = createAction('[Code] Get status Completed', props<{ status: boolean, clientName: string }>());
export const loadStatusFailed = createAction('[Code] Get status Failed', props<{ error: any }>());

export const resetSession = createAction('[Sessions] Reset session', props<{ sessionId: number }>());
export const resetSessionCompleted = createAction('[Sessions] Reset session completed');
export const resetSessionCancelled = createAction('[Sessions] Reset session failed');
export const resetSessionFailed = createAction('[Sessions] Reset session failed', props<{ error: any }>());

export const restartSession = createAction('[Sessions] Restart session', props<{ sessionId: number }>());
export const restartSessionCompleted = createAction('[Sessions] Restart session completed');
export const restartSessionCancelled = createAction('[Sessions] Restart session failed');
export const restartSessionFailed = createAction('[Sessions] Restart session failed', props<{ error: any }>());

export const showStatus = createAction('[Sessions] Show status', props<{ session: SessionDTO, emails: string }>());
export const statusDialogOpened = createAction('[Sessions] Status dialog opened', props<{ dialogId: string }>());
export const closeStatusDialog = createAction('[Sessions] Close status dialog');

export const reloadStatus = createAction('[Status] Reload status', props<{ sessionId: number, apiKey: string }>());
export const reloadSessionFailed = createAction('[Sessions] Reset session failed', props<{ error: any }>());

export const statusAll = createAction('[Sessions] Status all');
export const statusAllCompleted = createAction('[Sessions] Status all completed');
export const statusAllFailed = createAction('[Sessions] Status all failed', props<{ error: any }>());