import { createAction, props } from '@ngrx/store';

import { Intent, IntentDTO, IntentFilters, IntentTestDTO } from '../../commons/models/intent.model';

export const setClient = createAction('[Intents] Set Client', props<{ clientId: number }>());

export const loadIntents = createAction('[Intents] Load intents', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: IntentFilters }>());
export const loadIntentsCompleted = createAction('[Intents] Load intents Completed', props<{ intents: IntentDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: IntentFilters }>());
export const loadIntentsFailed = createAction('[Intents] Load intents Failed', props<{ error: any }>());

export const changePage = createAction('[Intents] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Intents] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Intents] Change filters', props<{ filters: IntentFilters }>());

export const saveIntent = createAction('[Intents] Add intent', props<{ intent: IntentDTO }>());
export const saveIntentCompleted = createAction('[Intents] Save intent Completed', props<{ intent: IntentDTO }>());
export const saveIntentFailed = createAction('[Intents] Add intent Failed', props<{ error: any }>());

export const openTestDialog = createAction('[Intents] Open test dialog', props<{ action: string }>());
export const testDialogOpened = createAction('[Intents] Test dialog opened', props<{ testDialogId: string }>());
export const closeTestDialog = createAction('[Intents] Close test dialog');

export const testIntent = createAction('[Intents] Test intent', props<{ intent: IntentTestDTO }>());
export const testIntentCompleted = createAction('[Intents] Test intent Completed', props<{ response: any }>());
export const testIntentFailed = createAction('[Intents] Test intent Failed', props<{ error: any }>());

export const editIntent = createAction('[Intents] Edit intent', props<{ intent: Intent }>());

export const deleteIntent = createAction('[Intents] Delete intent', props<{ id: number }>());
export const deleteIntentCompleted = createAction('[Intents] Delete intent success', props<{ intent: IntentDTO }>());
export const deleteIntentFailure = createAction('[Intents] Delete intent failure', props<{ error: any }>());

export const openEditDialog = createAction('[Intents] Open edit dialog', props<{ intent?: Intent }>());
export const editDialogOpened = createAction('[Intents] Edit dialog opened', props<{ dialogId: string }>());
export const closeEditDialog = createAction('[Intents] Close edit dialog');

export const intentLogs = createAction('[Intents] Intent logs', props<{ intent: IntentDTO }>());