import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';

import { MaterialModule } from './../material/material.module';
import { BlacklistFiltersComponent } from './blacklist-filters/blacklist-filters.component';
import { BlacklistListComponent } from './blacklist-list/blacklist-list.component';
import { ClientFiltersComponent } from './client-filters/client-filters.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ShowForDirective } from './directives/show-for.directive';
import { LibraryComponent } from './library/library.component';
import { MessageFiltersComponent } from './message-filters/message-filters.component';
import { ActionButtonComponent } from './message-list/action-button/action-button.component';
import { MessageListComponent } from './message-list/message-list.component';
import { ByteFormatPipe } from './pipes/byte-format.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DelayPipe } from './pipes/delay.pipe';
import { PrettyjsonPipe } from './pipes/prettyjson.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SessionStatusPipe } from './pipes/session-status.pipe';
import { SessionTypePipe } from './pipes/session-type.pipe';
import { SourceTypePipe } from './pipes/source-type.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { TemplateProviderPipe } from './pipes/template-provider.pipe';
import { UserRolePipe } from './pipes/user-role.pipe';
import { SequenceEditComponent } from './sequence-edit/sequence-edit.component';
import { TemplateFiltersComponent } from './template-filters/template-filters.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { TopnavComponent } from './topnav/topnav.component';
import { UserFieldComponent } from './user-field/user-field.component';
import { UserFiltersComponent } from './user-filters/user-filters.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserSelectionComponent } from './user-selection/user-selection.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule,
    MatDialogModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    SessionTypePipe,
    TemplateProviderPipe,
    UserRolePipe,
    SafeUrlPipe,
    SessionStatusPipe,
    DateFormatPipe,
    StatusPipe,
    ClientListComponent,
    TemplateListComponent,
    MessageListComponent,
    ClientFiltersComponent,
    UserListComponent,
    UserFiltersComponent,
    TemplateFiltersComponent,
    MessageFiltersComponent,
    ConfirmDialogComponent,
    SequenceEditComponent,
    PrettyjsonPipe,
    ShowForDirective,
    LibraryComponent,
    TopnavComponent,
    UserFieldComponent,
    UserSelectionComponent,
    BlacklistFiltersComponent,
    BlacklistListComponent,
    SourceTypePipe
  ],
  declarations: [
    SessionTypePipe,
    TemplateProviderPipe,
    UserRolePipe,
    SafeUrlPipe,
    PrettyjsonPipe,
    DelayPipe,
    SessionStatusPipe,
    DateFormatPipe,
    StatusPipe,
    ByteFormatPipe,
    ConfirmDialogComponent,
    ClientListComponent,
    ClientFiltersComponent,
    UserListComponent,
    UserFiltersComponent,
    TemplateListComponent,
    MessageListComponent,
    TemplateFiltersComponent,
    MessageFiltersComponent,
    SequenceEditComponent,
    ShowForDirective,
    LibraryComponent,
    ActionButtonComponent,
    TopnavComponent,
    UserFieldComponent,
    UserSelectionComponent,
    BlacklistFiltersComponent,
    BlacklistListComponent,
    SourceTypePipe
  ],
  providers: []
})
export class SharedModule { }
