<div class="action-button-container">
  <button
    class="action-button"
    mat-raised-button
    color="primary"
    [mat-menu-trigger-for]="actionMenu"
    (click)="$event.stopPropagation()"
  >
    AZIONI
  </button>
  <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
    <button mat-menu-item (click)="sendMessages()">Invia subito</button>
    <button mat-menu-item (click)="rescheduleMessages()">
      Riprogramma messaggi
    </button>
    <button mat-menu-item (click)="cancelMessages()">Annulla messaggi</button>
  </mat-menu>
</div>
