import { Action, createReducer, on } from '@ngrx/store';

import * as MailUsersAction from '../actions/mail-users.actions';
import { MailUsers } from 'src/app/commons/models/mail-users.model';

export interface MailUsersState {
  dialogId: string,
  mailUsers: MailUsers
};

const initialState: MailUsersState = {
  dialogId: null,
  mailUsers: null
};

const userReducer = createReducer(
  initialState,
  on(MailUsersAction.dialogOpened, (state, { dialogId }): MailUsersState => {
    return { ...state, dialogId };
  })
);

export function reducer(state: MailUsersState | undefined, action: Action) {
  return userReducer(state, action);
}

