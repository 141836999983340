import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sessionStatus'
})
export class SessionStatusPipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? "COMPLETATO" : "DISCONNESSO";
  }

}