import { createAction, props } from '@ngrx/store';

import { Message, MessageDTO, MessageFilters, SingleMessageDTO } from '../../commons/models/message.model';

export const loadMessages = createAction('[Messages] Load messages', props<{ templateId?: number, page: number, perPage: number, order?: string, direction?: string, filters?: MessageFilters }>());
export const loadMessagesCompleted = createAction('[Messages] Load messages Completed', props<{ messages: MessageDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: MessageFilters }>());
export const loadMessagesFailed = createAction('[Messages] Load messages Failed', props<{ error: any }>());

export const loadMessage = createAction('[Message] Load message', props<{ id: number }>());
export const loadMessageCompleted = createAction('[Message] Load message Completed', props<{ message: MessageDTO }>());
export const loadMessageFailed = createAction('[Message] Load message Failed', props<{ error: any }>());

export const selectMessage = createAction('[Message] Select message', props<{ message: Message }>());
export const messageDialogOpened = createAction('[Message] Detail dialog opened', props<{ dialogId: string }>());
export const closeMessageDialog = createAction('[Message] Close detail dialog');

export const changePage = createAction('[Message] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Message] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Message] Change filters', props<{ filters: MessageFilters }>());

export const cancelMessage = createAction('[Message] Cancel message', props<{ message: MessageDTO }>());
export const cancelSequence = createAction('[Message] Cancel sequence', props<{ message: MessageDTO }>());
export const cancelCompleted = createAction('[Message] Cancel Completed', props<{ count: number, id: string[] }>());
export const cancelFailed = createAction('[Message] Cancel Failed', props<{ error: any }>());

export const selectNewDate = createAction('[Message] Reschedule message - Select new date', props<{ message: MessageDTO }>());
export const rescheduleMessage = createAction('[Message] Reschedule message', props<{ message: MessageDTO, date: Date }>());
export const rescheduleMessageCompleted = createAction('[Message] Reschedule Completed', props<{ message: MessageDTO }>());
export const rescheduleMessageFailed = createAction('[Message] Reschedule Failed', props<{ error: any }>());

export const cancelMessages = createAction('[Message] Cancel messages', props<{ messages: number[] }>());
export const cancelMessagesCompleted = createAction('[Message] Cancel messages Completed', props<{ id: string[], count: number }>());
export const cancelMessagesFailed = createAction('[Message] Cancel messages Failed', props<{ error: any }>());

export const selectNewDateMessages = createAction('[Message] Reschedule messages - Select new date', props<{ messages: number[] }>());
export const rescheduleMessages = createAction('[Message] Reschedule messages message', props<{ messages: number[], date: Date }>());
export const rescheduleMessagesCompleted = createAction('[Message] Reschedule messages Completed', props<{ id: string[], count: number }>());
export const rescheduleMessagesFailed = createAction('[Message] Reschedule messages Failed', props<{ error: any }>());

export const sendSingleMessage = createAction('[Message] Send single message', props<{ message: SingleMessageDTO, close?: boolean }>());
export const sendSingleMessageCompleted = createAction('[Message] Send single message Completed', props<{ messageId: string, delay: boolean, close?: boolean }>());
export const sendSingleMessageFailed = createAction('[Message] Send single message Failed', props<{ error: any }>());
