import { Action, createReducer, on } from '@ngrx/store';
import { MessageDTO, MessageFilters } from 'src/app/commons/models/message.model';

import * as MessageActions from '../actions/message.actions';

export interface MessageState {
  list: MessageDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: MessageFilters,
  templateId: number,
  dialogId: string
};

const initialState: MessageState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 2000,
  order: 'id',
  direction: 'desc',
  filters: null,
  templateId: null,
  dialogId: null,
};

const messageReducer = createReducer(
  initialState,
  on(MessageActions.loadMessages, (state, { templateId }) => {
    return { ...state, templateId };
  }),
  on(MessageActions.loadMessagesCompleted, (state, { messages, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, list: messages, currentPage, total, perPage, order, direction, filters };
  }),
  on(MessageActions.messageDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(MessageActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
);

export function reducer(state: MessageState | undefined, action: Action) {
  return messageReducer(state, action);
}
