import { UntypedFormGroup } from '@angular/forms';
import { matchAll } from 'src/app/helpers/string.utils';

import { Base, BaseDTO } from './base.model';
import { TEMPLATE_MEDIA_TEXT_NOT_SUPPORTED } from 'src/app/constants/template.constants';

export interface TemplateFilters {
  search?: string;
  showInactive?: boolean;
  ids?: number[];
}

export type TemplateProviders = "sendingblue" | "mailchimp" | "mailup" | "activecampaign";

export const defaultFilters: TemplateFilters = {
  search: "",
  showInactive: false,
  ids: []
}

export interface TemplateDelay {
  source: "webhook" | "param";
  date_param?: string;
  format?: string;
  time_param?: string;
  type: "before" | "after";
  minutes: number;
}

export interface TemplateLimit {
  start: string;
  end: string
}

export const DATE_FORMATS: Array<{ value: string, label: string }> = [
  {
    value: 'd/m/y',
    label: 'DD/MM/YY'
  },
  {
    value: 'd/m/Y',
    label: 'DD/MM/YYYY'
  },
  {
    value: 'Y/m/d',
    label: 'YYYY/MM/DD'
  },
  {
    value: 'y/m/d',
    label: 'YY/MM/DD'
  },
  {
    value: 'Y/d/m',
    label: 'YYYY/DD/MM'
  },
  {
    value: 'y/d/m',
    label: 'YY/DD/MM'
  },
  {
    value: 'd-m-y',
    label: 'DD-MM-YY'
  },
  {
    value: 'd-m-Y',
    label: 'DD-MM-YYYY'
  },
  {
    value: 'Y-m-d',
    label: 'YYYY-MM-DD'
  },
  {
    value: 'y-m-d',
    label: 'YY-MM-DD'
  },
  {
    value: 'Y-d-m',
    label: 'YYYY-DD-MM'
  },
  {
    value: 'y-d-m',
    label: 'YY-DD-MM'
  }
]

export interface TemplateDTO extends BaseDTO {
  nome: string;
  sequence_id: number;
  phone_field?: string;

  media_url?: string;
  media_nome?: string;
  testo: string;
  provider: TemplateProviders;
  messages_count?: number;
  attivo: boolean;
  position: number;
  delay: TemplateDelay;
  limit: TemplateLimit;
}

export class Template extends Base {
  nome: string;
  phoneField?: string;
  sequenceId: number;
  mediaUrl?: string;
  mediaNome?: string;
  testo: string;
  provider: TemplateProviders;
  messaggiInviati?: number;
  attivo: boolean;
  position: number;
  delay: TemplateDelay;
  limit: TemplateLimit;
  parameters: string[];

  constructor(source: TemplateDTO) {
    super(source);
    if (source) {
      this.nome = source.nome;
      this.sequenceId = source.sequence_id;
      this.mediaUrl = source?.media_url;
      this.mediaNome = source?.media_nome;
      this.testo = source.testo;
      this.provider = source.provider;
      this.messaggiInviati = source.messages_count;
      this.attivo = source.attivo;
      this.position = source.position;
      this.delay = source.delay;
      this.limit = source.limit;

      this.parameters = matchAll(/{{(.*?)}}/g, this.testo);
      let mediaParam = matchAll(/{{(.*?)}}/g, this.mediaUrl);
      let mediaNameParam = matchAll(/{{(.*?)}}/g, this.mediaNome);
      this.parameters = [...this.parameters, ...mediaParam, ...mediaNameParam, this.delay?.time_param, this.delay?.date_param].filter(value => !!value);
    }
  }

  toDTO(): TemplateDTO {
    let result: TemplateDTO = <TemplateDTO>super.toDTO();
    result.nome = this.nome;
    result.sequence_id = this.sequenceId;
    result.media_url = this?.mediaUrl;
    result.media_nome = this?.mediaNome;
    result.testo = this.testo;
    result.provider = this.provider;
    result.attivo = this.attivo;
    result.position = this?.position;
    result.delay = this?.delay;
    result.limit = this.limit;

    return result;
  }

  clone(): Template {
    let template: Template = new Template(this.toDTO());
    template.nome = `COPIA - ${template.nome}`;
    delete template.id;
    return template;
  }


  static fromFormGroup(formGroup: UntypedFormGroup, original?: Template): Template {
    const formModel = formGroup.getRawValue();
    let template: Template = new Template(null);
    if (original) {
      template.id = original.id;
      template.sequenceId = original.sequenceId;
      template.position = original.position;
    }
    template.nome = formModel.nome;
    template.mediaUrl = formModel?.mediaUrl;
    template.mediaNome = formModel?.mediaNome;

    if(formGroup.get('testo').disabled) {
      template.testo = null;
    } else {
      template.testo = formModel.testo;
    }

    template.provider = formModel.provider;
    template.attivo = formModel.attivo;
    template.delay = formModel.delay;
    template.limit = formModel.limit;

    return template;
  }

  get hasLimit(): boolean {
    return !!this.limit?.start && !!this.limit?.end;
  }
}
