import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function fileSizeValidator(maxFileSize: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const file = control.value;
        if (file) {
            const fileSize = file.size;
            if (fileSize >= maxFileSize) {
                return {
                    error: `La grandezza massima degli allegati è di ${maxFileSize / (1024 * 1024)}mB`
                };
            } else {
                return null;
            }
        }
        return null;
    };
}

export function fileExtensionValidator(accepts: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const file = control.value;
        if (file && file.name) {
            const fileName = file.name;
            let ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
            if (!accepts.includes(ext)) return { error: `Estensione .${ext} non valida, formati accettati: ${accepts.join(', ')}` };
        }
        return null;
    };
}