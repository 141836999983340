import { createAction, props } from '@ngrx/store';

import { MessageDTO } from '../../commons/models/message.model';
import { Template, TemplateDTO, TemplateFilters } from '../../commons/models/template.model';

export const loadTemplates = createAction('[Templates] Load templates', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: TemplateFilters }>());
export const loadTemplatesCompleted = createAction('[Templates] Load templates Completed', props<{ templates: TemplateDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: TemplateFilters }>());
export const loadTemplatesFailed = createAction('[Templates] Load templates Failed', props<{ error: any }>());

export const loadTemplate = createAction('[Templates] Load template', props<{ id: number }>());
export const loadTemplateCompleted = createAction('[Templates] Load template Completed', props<{ template: TemplateDTO }>());
export const loadTemplateFailed = createAction('[Templates] Load template Failed', props<{ error: any }>());

export const changePage = createAction('[Templates] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Templates] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Templates] Change filters', props<{ filters: TemplateFilters }>());

export const cloneTemplate = createAction('[Templates] Clone template', props<{ template: TemplateDTO }>());
export const editTemplate = createAction('[Templates] Edit template', props<{ template: Template }>());
export const selectTemplate = createAction('[Templates] Select template', props<{ template: Template }>());
export const templateDialogOpened = createAction('[Templates] Detail dialog opened', props<{ dialogId: string }>());
export const closeTemplateDialog = createAction('[Templates] Close detail dialog');

export const testTemplate = createAction('[Templates] Test template', props<{ template: Template, data: Object }>());
export const testTemplateCompleted = createAction('[Templates] Test template completed', props<{ message: MessageDTO }>());
export const testTemplateFailed = createAction('[Templates] Test template failed', props<{ error: any }>());

export const copyTemplate = createAction('[Templates] Copy template webhook', props<{ template: Template }>());

export const saveTemplate = createAction('[Templates] Save template', props<{ template: Template }>());
export const saveTemplateCompleted = createAction('[Templates] Save template completed', props<{ template: TemplateDTO }>());
export const saveTemplateFailed = createAction('[Templates] Save template failed', props<{ error: any }>());

export const deleteTemplate = createAction('[Templates] Delete template', props<{ template: Template }>());
export const deleteTemplateCompleted = createAction('[Templates] Delete template completed', props<{ template: TemplateDTO }>());
export const deleteTemplateCancelled = createAction('[Templates] Delete template cancelled');
export const deleteTemplateFailed = createAction('[Templates] Delete template failed', props<{ error: any }>());
