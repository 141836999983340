import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Sequence } from 'src/app/commons/models/sequence.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as SequenceActions from 'src/app/store/actions/sequence.actions';
import { AppState } from 'src/app/store/reducers';


@Component({
  selector: 'app-sequence-edit',
  templateUrl: './sequence-edit.component.html',
  styleUrls: ['./sequence-edit.component.scss']
})
export class SequenceEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _sequence: Sequence;
  public sessionId: number;

  sequenceForm: UntypedFormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: UntypedFormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data) {
      this.sequence = this.data.sequence;
      this.sessionId = this.data.sessionId;
    }

    this._createForm();
    this.ngOnChanges();
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let sequenceGroup = {
      name: ["", Validators.required],
      phoneField: ["", Validators.required],
      active: ["", Validators.required],
    }

    this.sequenceForm = this.fb.group(sequenceGroup);
  }

  private ngOnChanges() {
    if (this.sequenceForm) {
      this.sequenceForm.reset();

      if (this.sequence) {
        this._initFormValues(this.sequence);
      }

      this._initDefaultValues();
    }
  }

  private _initDefaultValues() {
    if (this.sequenceForm) {
      this.sequenceForm.get("active").setValue(true);
    }
  }

  private _initFormValues(sequence: Sequence): void {
    if (sequence) {
      this.sequenceForm.patchValue({
        name: sequence.name,
        phoneField: sequence.phoneField,
        active: sequence.active
      });
    }
  }

  get sequence(): Sequence {
    return this._sequence;
  }

  set sequence(sequence: Sequence) {
    this._sequence = sequence;
    this.ngOnChanges();
  }

  private _prepareSaveSequence(): Sequence {
    const savingSequence: Sequence = Sequence.fromFormGroup(this.sequenceForm, this.sequence);
    savingSequence.sessionId = this.sessionId;
    return savingSequence;
  }

  save() {
    let unsavedSequence = this._prepareSaveSequence();
    this.store$.dispatch(SequenceActions.saveSequence({ sequence: unsavedSequence.toDTO() }));
  }

  close() {
    if (this.sequenceForm.pristine) {
      this.store$.dispatch(SequenceActions.closeSequenceDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(SequenceActions.closeSequenceDialog())
          }
        });
    }
  }

  deleteSequence() {
    if (this.sequence) {
      this.store$.dispatch(SequenceActions.deleteSequence({ sequence: this.sequence.toDTO() }));
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
