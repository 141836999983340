<header mat-dialog-title class="u-flex-full">
  <span>
    <span>Info Pagamento</span>
  </span>
</header>
<mat-dialog-content>
  <div class="u-full-width mt-1">
    <span
      >Profilo: <b>{{ user.profile }}</b>
    </span>
  </div>

  <div class="u-full-width mt-1">
    <span>
      Prossimo pagamento:<br />
      In data: <b>{{ user.expiration }}</b
      ><br />
      Importo: <b>{{ user.amount }}</b>
    </span>
  </div>

  <div class="u-full-width mt-1">
    <span>
      Metodo di pagmento: <b>{{ user.paymentMethod }}</b>
    </span>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Chiudi
  </button>
</mat-dialog-actions>
