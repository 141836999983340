import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as LibraryActions from 'src/app/store/actions/library.actions';
import { AppState } from 'src/app/store/reducers';
import * as LibrarySelectors from 'src/app/store/selectors/library.selectors';
import { fileExtensionValidator, fileSizeValidator } from 'src/app/helpers/file.validator';
import { VALID_EXTENSIONS } from 'src/app/constants/media.constants';

@Component({
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  files: string[];
  accepts: string[] = VALID_EXTENSIONS;
  acceptsString: string = this.accepts.map((v) => `.${v}`).join(',')

  readonly maxSize = 15 * 1024 * 1024; // 15MB

  temporaryLibrary: boolean = false;

  fileCtrl: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required, fileSizeValidator(this.maxSize), fileExtensionValidator(this.accepts)]);

  constructor(private store$: Store<AppState>, private alertService: AlertService, private actions$: Actions, @Inject(MAT_DIALOG_DATA) public data) {
    this.store$.pipe(
      takeUntil(this.unsubscribe$),
      select(LibrarySelectors.getFiles),
    ).subscribe(files => this.files = files);

    this.actions$.pipe(
      ofType(LibraryActions.uploadFileCompleted),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.fileCtrl.reset();
    })

    this.temporaryLibrary = data?.temp;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectFile(filename: string) {
    if (filename) {
      this.store$.dispatch(LibraryActions.resolveUrl({ filename, temp: this.temporaryLibrary }));
    }
  }

  removeFile(filename: string) {
    if (filename) {
      this.store$.dispatch(LibraryActions.removeFile({ filename, temp: this.temporaryLibrary }));
    }
  }

  download(filename: string) {
    if (filename) {
      this.store$.dispatch(LibraryActions.downloadFile({ filename, temp: this.temporaryLibrary }));
    }
  }

  uploadFile() {
    if (this.fileCtrl.valid) {
      this.store$.dispatch(LibraryActions.uploadFile({ file: this.fileCtrl.value, temp: this.temporaryLibrary }));
    }
  }

  close() {
    this.store$.dispatch(LibraryActions.closeLibraryDialog())
  }
}
