import { createSelector } from '@ngrx/store';

import { selectMessageState } from '../reducers';
import { MessageState } from '../reducers/message.reducer';
import { MessageDTO } from 'src/app/commons/models/message.model';

export const getSessionId = createSelector(
  selectMessageState,
  (state: MessageState) => { return { templateId: state.templateId }; },
);

export const getMessagesTableState = createSelector(
  selectMessageState,
  (state: MessageState) => { return { templateId: state.templateId, total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters } }
);

export const getMessages = createSelector(
  selectMessageState,
  (state: MessageState) => state.list
);

export const getTotalMessages = createSelector(
  getMessagesTableState,
  (tableState) => tableState.total
);

export const getMessagesCurrentPage = createSelector(
  getMessagesTableState,
  (tableState) => tableState.currentPage
);

export const getMessagesPerPage = createSelector(
  getMessagesTableState,
  (tableState) => tableState.perPage
);

export const getMessagesOrder = createSelector(
  getMessagesTableState,
  (tableState) => tableState.order
);

export const getMessagesDirection = createSelector(
  getMessagesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getMessagesTableState,
  (tableState) => tableState.filters
);

export const getMessageDialogId = createSelector(
  selectMessageState,
  (state: MessageState) => state.dialogId
);