import { Pipe, PipeTransform } from '@angular/core';
import { TemplateProviders } from 'src/app/commons/models/template.model';

@Pipe({
  name: 'templateProvider'
})
export class TemplateProviderPipe implements PipeTransform {

  transform(value: TemplateProviders): unknown {
    switch (value) {
      case "sendingblue":
        return "Sending Blue";
      case "mailchimp":
        return "Mail Chimp";
      case "mailup":
        return "Mail Up";
      case "activecampaign":
        return "Active Campaign";
      default:
        return "";
    }
  }

}