import { Action, createReducer, on } from '@ngrx/store';

import * as BlacklistActions from '../actions/blacklist.actions';
import { BlacklistDTO, BlacklistFilters } from 'src/app/commons/models/blacklist.model';

export interface BlacklistState {
  list: BlacklistDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: BlacklistFilters,
  dialogId: string,
};

const initialState: BlacklistState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
};

const blacklistReducer = createReducer(
  initialState,
  on(BlacklistActions.loadBlacklistsCompleted, (state, { blacklists, currentPage, total, perPage, order, direction, includes }): BlacklistState => {
    return { ...state, list: blacklists, currentPage, total, perPage, order, direction, includes };
  }),
  on(BlacklistActions.blacklistDialogOpened, (state, { dialogId }): BlacklistState => {
    return { ...state, dialogId };
  }),
  on(BlacklistActions.changeFilters, (state, { filters }): BlacklistState => {
    return { ...state, currentPage: 1, filters };
  }),
);

export function reducer(state: BlacklistState | undefined, action: Action) {
  return blacklistReducer(state, action);
}

