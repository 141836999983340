import { Pipe, PipeTransform } from '@angular/core';
import { TemplateDelay } from 'src/app/commons/models/template.model';
import { splitMinutes } from 'src/app/helpers/time.utils';

@Pipe({
  name: 'delay'
})
export class DelayPipe implements PipeTransform {
  transform(value: TemplateDelay): string {
    if (value) {
      const source = value.source === 'param' ? 'Data/ora evento' : 'Webhook';
      if (value?.minutes) {
        const { days, hours, minutes } = splitMinutes(value.minutes);
        const type = value.type == 'after' ? "ritardo" : "anticipo";
        return `${source}, ${type}: ${days}g - ${hours}h - ${minutes}m`;
      } else {
        return `${source}`;
      }
    }
    return "";
  }

}
