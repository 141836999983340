import { Action, createReducer, on } from '@ngrx/store';

import * as SessionActions from '../actions/session.actions';

export interface SessionState {
  code: string;
  status: boolean;
  clientName: string;
  dialogId: string;
  statusDialogId: string;
};

const initialState: SessionState = {
  code: null,
  status: false,
  clientName: null,
  dialogId: null,
  statusDialogId: null
};

const codeReducer = createReducer(
  initialState,
  on(SessionActions.loadCodeCompleted, (state, { code }) => {
    return { ...state, code };
  }),
  on(SessionActions.loadStatusCompleted, (state, { status, clientName }) => {
    return { ...state, status, clientName };
  }),
  on(SessionActions.sessionDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  })
);

export function reducer(state: SessionState | undefined, action: Action) {
  return codeReducer(state, action);
}
